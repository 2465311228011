import React from "react";
import { IonPage, IonContent, IonButton } from "@ionic/react";
//@ts-ignore
import { useHistory } from "react-router-dom";
const NotFound: React.FC = () => {
  const history = useHistory();
  return (
    <IonPage>
      <IonContent
        style={{ "--ion-background-color": "#ae0606", textAlign: "center" }}
      >
        <img
          alt=""
          src={require("../images/404.png").default}
          style={{ width: "150px" }}
        />
        <h1 className="eina" style={{ color: "#fff" }}>
          404
        </h1>
        <h3 style={{ color: "#fff" }}>This page does not exist.</h3>
        <IonButton color="light" onClick={() => history.push("/")}>
          Return to Home
        </IonButton>
      </IonContent>
    </IonPage>
  );
};

export default NotFound;
