import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonGrid,
  IonCol,
  IonRow,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonLoading,
} from "@ionic/react";
import React, { useState } from "react";
import { loginUser } from "../firebaseconfig";
import { useDispatch } from "react-redux";
import "../theme/HomePage.css";
import { setUserState } from "../redux/actions";

const Home: React.FC = () => {
  const [busy, setBusy] = useState<boolean>(false);
  const dispatch = useDispatch();

  async function guestLogin() {
    setBusy(true);
    const res = await loginUser("guest@ecogohk.org", "K779cStG4$r5J2w"); // [CONSTANT] GUEST ACCOUNT DETAILS
    if (res) {
      dispatch(setUserState(res.user));
      window.location.reload();
    } else {
      // error occured
    }
    setBusy(false);
  }

  const [renderIndex, setRenderIndex] = useState<number>(0);

  if(renderIndex < 1){

    // Chatbox

  (function (d, s, id) {
    var fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    var js:any = d.createElement(s); js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
    fjs.parentNode!.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));

  //

    setRenderIndex(renderIndex + 1);
  }

  

  return (
    <IonPage>
      <IonLoading isOpen={busy} message={"Please wait."} spinner="dots" />
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle className="eina">EcoGo</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding container">
        <IonCard className="ion-text-center fix-width-card">
          <IonCardHeader>
            <img
              alt=""
              src={require("../images/icon.png").default}
              width={100}
            />
            <IonCardTitle className="eina">EcoGo</IonCardTitle>
            <IonCardSubtitle>
            EcoGo is Hong Kong's one-stop platform for sustainability. Find all the local eco-friendly business near your area!
            </IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent>
            <IonButton routerLink="/tour" color="warning">
              Launch Tour
            </IonButton>
            <br />

            <a href="https://play.google.com/store/apps/details?id=org.ecogohk">
              <img
                style={{ width: "200px", maxWidth: "75%" }}
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              />
            </a>
          </IonCardContent>
        </IonCard>
        <IonCard className="ion-text-center fix-width-card">
          <IonCardHeader>
            <IonCardTitle>Start Using EcoGo</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            <IonButton
              color="success"
              className="blackText"
              onClick={() => guestLogin()}
            >
              Start Exploring
            </IonButton>
            <br />
            <IonButton size="small" routerLink="/login" color="primary">
              Login
            </IonButton>
            <IonButton size="small" routerLink="/signup" color="tertiary">
              SignUp
            </IonButton>
          </IonCardContent>
        </IonCard>
      </IonContent>
      <div id="fb-root"></div>
      <div id="fb-customer-chat" className="fb-customerchat" {...{"page_id": "100332755670240", "attribution": "biz_inbox"}}></div>
    </IonPage>
  );
};

export default Home;
