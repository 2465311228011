import React, { useState } from "react";
import {
  IonContent,
  IonList,
  IonListHeader,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonPage,
} from "@ionic/react";
import { useSelector } from "react-redux";
import "../theme/community.css";

const Community: React.FC = (props) => {
  const user = useSelector((state: any) => state.user.data);
  const [segment, setSegment] = useState<"public" | "your" | "ecogo">("public");
  const [renderIndex, setRenderIndex] = useState<number>(0);

  if (renderIndex < 1) {
    // Social Media Feed

    (function () {
      var i,
        e,
        d = document,
        s = "script";
      i = d.createElement("script");
      i.async = true;
      i.charset = "UTF-8";
      i.src =
        "https://cdn.curator.io/published/7bd3e117-5ca1-4a11-8dc4-402235fb087f.js";
      e = d.getElementsByTagName(s)[0];
      e.parentNode?.insertBefore(i, e);
    })();

    //

    setRenderIndex(renderIndex + 1);
  }

  return (
    <IonPage>
      <IonSegment onIonChange={(e: any) => setSegment(e.detail.value)} value={segment}>
        <IonSegmentButton value="public">
          <IonLabel>Public Feed</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="your">
          <IonLabel>Your Posts</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="ecogo">
          <IonLabel>From EcoGo</IonLabel>
        </IonSegmentButton>
      </IonSegment>

      <IonContent hidden={segment !== "public"}>
        <IonList>
          <IonListHeader style={{ color: "var(--ion-color-primary)" }}>
            <IonLabel className="eina ion-text-wrap">
              {/* <h2 className="eina">Discourse</h2> */}
              <p>Keep checking this section for our upcoming updates.</p>
            </IonLabel>
          </IonListHeader>
        </IonList>
      </IonContent>
      <IonContent hidden={segment !== "your"}>
        <IonList>
          <IonListHeader style={{ color: "var(--ion-color-primary)" }}>
            <IonLabel className="eina ion-text-wrap">
              {/* <h2 className="eina">Your </h2> */}
              <p>Keep checking this section for our upcoming updates.</p>
            </IonLabel>
          </IonListHeader>
        </IonList>
      </IonContent>
      <IonContent hidden={segment !== "ecogo"}>
        <div id="curator-feed-default-feed-layout">
          <a
            href="https://curator.io"
            target="_blank"
            rel="noreferrer"
            className="crt-logo crt-tag"
          >
            Powered by Curator.io
          </a>
        </div>
      </IonContent>

      {/* <div hidden={user.email !== "guest@ecogohk.org"}>PLEASE LOG IN</div>
      <div hidden={user.email === "guest@ecogohk.org"}></div> */}
    </IonPage>
  );
};

export default Community;
