import React, { useRef } from "react";
import { IonSlide, IonSlides, IonPage, IonContent, IonButton } from "@ionic/react";
import "../theme/slides.css";
import { useHistory } from "react-router";

const Slides: React.FC = () => {
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const history = useHistory();
  return (
    <IonPage>
      <IonContent>
        <IonSlides pager className="slides" ref={slidesRef}>
          <IonSlide className="signup slide">
            <img alt="" src={require("../images/tour/signup.png").default}/>
            
            <h2 className="eina">SignUp within Seconds</h2>
            <p>Find local eco-friendly businesses all around your neighborhood!</p>
            <IonButton color="light" onClick={() => slidesRef.current?.slideNext()}>
              Next
            </IonButton>
          </IonSlide>
          <IonSlide className="search slide">
            <img alt="" src={require("../images/tour/search.png").default}/>
            
            <h2 className="eina">Powerful Search</h2>
            <p>Search for nearby eco friendly businesses in a snap, powered by filters for prices, distance, and much more.</p>
            <IonButton color="danger" onClick={() => slidesRef.current?.slideNext()}>
              Next
            </IonButton>
          </IonSlide>
          <IonSlide className="queue slide">
            <img alt="" src={require("../images/tour/queue.png").default}/>
            
            <h2 className="eina">Goodbye to Time-killing Google Searches</h2>
            <p>No more need to dig through forums or search results to find legitment eco friendly businesses.</p>
            <IonButton color="warning" onClick={() => slidesRef.current?.slideNext()}>
              Next
            </IonButton>
          </IonSlide>
          <IonSlide className="track slide">
            <img alt="" src={require("../images/tour/track.png").default}/>
            
            <h2 className="eina">Live Reminders</h2>
            <p>We will give you a notification when you are within a 5 minute distance from a store you have previously bookmarked!</p>
            <IonButton color="dark" onClick={() => slidesRef.current?.slideNext()}>
              Next
            </IonButton>
          </IonSlide>
          <IonSlide className="wait slide">
            <img alt="" src={require("../images/tour/wait.png").default}/>
            
            <h2 className="eina">Shop in Peace</h2>
            <p>All store listings are verified - both by us and other users! No more need to worry if the store is really sustaianble.</p>
            <IonButton color="primary" onClick={() => slidesRef.current?.slideNext()}>
              Next
            </IonButton>
          </IonSlide>
          <IonSlide className="distancing slide">
            <img alt="" src={require("../images/tour/distancing.png").default}/>
            
            <h2 className="eina">Help Others!</h2>
            <p>By using our platform, you are helping both small business owners and other eco-friendly shoppers.</p>
            <IonButton color="tertiary" onClick={() => slidesRef.current?.slideNext()}>
              Next
            </IonButton>
          </IonSlide>
          <IonSlide className="welcome slide">
            <img alt="" src={require("../images/icon.png").default}/>
            
            <h2 className="eina">Start Today</h2>
            <p>What are you waiting for? Start today, for free!</p>
            <IonButton color="success" onClick={() => history.push("/")}>
              Start
            </IonButton>
          </IonSlide>
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};

export default Slides;
