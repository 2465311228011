import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import { review, vendor } from "./myTypes";

const db = firebase.firestore();

export { db };

export async function saveReview(
  vendor: vendor,
  rating: number,
  review: string,
  images: string[],
  id: string | undefined
) {
  var imgname = [];
  for (var x = 0; x < images.length; x++) {
    imgname.push(
      firebase.auth().currentUser!.uid + "_" + vendor.id + "_" + x + ".jpg"
    );
    firebase
      .storage()
      .ref()
      .child(imgname[x])
      .putString(images[x], "data_url")
      .then(function (snapshot) {});
  }
  return db
    .collection("reviews")
    .doc(id)
    .set(
      {
        vendor: {
          name: vendor.name,
          location: vendor.location,
          id: vendor.id,
          category: vendor.category,
          type: vendor.type,
        },
        user: {
          name: firebase.auth().currentUser?.displayName,
          email: firebase.auth().currentUser?.email,
          id: firebase.auth().currentUser?.uid,
        },
        rating: rating,
        review: review,
        images: imgname,
        type: "store",
        time: firebase.firestore.Timestamp.fromDate(new Date()),
      },
      {
        merge: true,
      }
    )
    .then(function () {
      return true;
    })
    .catch(function (error) {
      return false;
    });
}

export async function getReviews(businessId: string) {
  return db
    .collection("reviews")
    .where("vendor.id", "==", businessId)
    .orderBy("time", "desc")
    .get()
    .then(function (querySnapshot) {
      let response: review[] = [];
      querySnapshot.forEach(function (doc) {
        var temp = doc.data() as review;
        temp.id = doc.id;
        response.push(temp);
      });
      return response;
    })
    .catch(function (error) {
      return false;
    });
}

export async function deleteReview(id: string) {
  return db
    .collection("reviews")
    .doc(id)
    .delete()
    .then(function () {
      return true;
    })
    .catch(function (error) {
      return false;
    });
}
