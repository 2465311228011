import React, { useState } from "react";
import {
  IonContent,
  IonApp,
  IonList,
  IonIcon,
  IonLabel,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonSearchbar,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonCol,
  IonRow,
  IonHeader,
  IonMenu,
  IonMenuToggle,
  IonSegment,
  IonSegmentButton,
  IonListHeader,
} from "@ionic/react";

import { coords, vendor } from "../myTypes";
import { getServiceData } from "../firebaseconfig_Vendor";
import { optionsOutline, cubeOutline, locationOutline } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { menuController } from "@ionic/core";
import { GoogleMap, Marker } from "@react-google-maps/api";
import "../theme/explore.css";

/*const average = (arr: number[]) => {
  return arr.reduce((p, c) => p + c, 0) / arr.length;
};*/

const Explore: React.FC<{ locationCoords: coords | undefined }> = ({
  locationCoords,
}) => {
  let history = useHistory();
  const [tabSection, setTabSection] = useState<string>("locations");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [distanceToSearchIn, setDistanceToSearchIn] = useState<number>(10); //kilometers
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [minPrice, setMinPrice] = useState<number>(0); //rupees
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [maxPrice, setMaxPrice] = useState<number>(); //rupees; undefined=no limit
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [categories, setCategories] = useState<Array<String>>([
    "Pet Supplies",
    "Food & Beverages",
    "Events",
    "Lifestyle & Home Products",
    "Baby Products",
    "Gifts",
    "Clothing & Cosmetics",
    "Second Hand",
    "Healthcare",
  ]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [maxQueue, setMaxQueue] = useState<number>(); //undefined=no limit
  const [vendorsData, setVendorsData] = useState<Array<vendor>>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const [searchText, setSearchText] = useState<string>("");
  const [oldLocationCoords, setOldLocationCoords] = useState<coords>();

  if (locationCoords) {
    if (oldLocationCoords !== locationCoords) {
      // console.log(locationCoords);
      getServices(locationCoords);
      setOldLocationCoords(locationCoords);
    }
  }
  async function getServices(location: coords | undefined) {
    if (location === undefined) location = locationCoords;
    const response: vendor[] = await getServiceData(
      location!,
      distanceToSearchIn,
      minPrice,
      maxPrice,
      categories,
      maxQueue
    );

    setVendorsData(response);
  }

  function Map() {
    let viewSize = Math.min(window.innerHeight - 200, window.innerWidth);

    if (tabSection === "locations")
      return (
        <GoogleMap
          zoom={Math.floor(
            Math.log2(
              (viewSize * 0.000264583333 * 591657550.5) /
                (2000 * distanceToSearchIn)
            )
          )}
          center={{
            lat: locationCoords?.lat as number,
            lng: locationCoords?.lng as number,
          }}
          mapContainerClassName="map-container"
          options={{
            fullscreenControl: false,
            zoomControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            rotateControl: false,
            mapTypeId: "hybrid",
          }}
        >
          <Marker
            position={{
              lat: locationCoords?.lat as number,
              lng: locationCoords?.lng as number,
            }}
            icon={{
              url: "https://firebasestorage.googleapis.com/v0/b/ecogollc.appspot.com/o/person.png?alt=media&token=14cc23d5-0b64-4377-9a5a-2b29e56b7efc",
            }}
          />
          {vendorsData.map((vendor, i) => {
            if (
              [
                vendor.name,
                vendor.category,
                JSON.stringify(vendor.serviceData),
                vendor.description,
                vendor.summary,
                vendor.email,
                vendor.phone,
                vendor.type,
                vendor.location.name,
                vendor.weblink,
              ]
                .join(" ")
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) > -1
            )
              return (
                <Marker
                  position={{
                    lat: vendor.location.coords.lat as number,
                    lng: vendor.location.coords.lng as number,
                  }}
                  animation={2}
                  title={vendor.name}
                  onClick={() => setSearchText(vendor.name)}
                />
              );
            else return <></>;
          })}
        </GoogleMap>
      );
    else return <></>;
  }

  return (
    <IonApp>
      <IonMenu
        side="start"
        menuId="filters"
        type="overlay"
        contentId="contentForMenu"
      >
        <IonHeader>
          <IonToolbar color="tertiary">
            <IonTitle>Filters</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent id="contentForMenu">
          {tabSection === "locations" ? (
            <div className="ion-padding">
              <IonList>
                <IonListHeader>
                  <IonLabel className="eina ion-text-wrap">
                    <h2 className="eina">Business Filters</h2>
                    <p>Keep checking this section for our upcoming updates.</p>
                  </IonLabel>
                </IonListHeader>
              </IonList>
            </div>
          ) : null}
          {tabSection === "products" ? (
            <div className="ion-padding">
              <IonList>
                <IonListHeader>
                  <IonLabel className="eina ion-text-wrap">
                    <h2 className="eina">Product Filters</h2>
                    <p>Keep checking this section for our upcoming updates.</p>
                  </IonLabel>
                </IonListHeader>
              </IonList>
            </div>
          ) : null}
        </IonContent>

        {/*<IonContent>
            <IonList lines="full" style={{ width: "100%" }}>
              <IonListHeader className="ion-text-wrap">
                Search for places within
              </IonListHeader>
              <IonItem>
                <IonRange
                  min={5}
                  max={100}
                  step={5}
                  snaps={true}
                  ticks={true}
                  color="primary"
                  onIonChange={(e) =>
                    setDistanceToSearchIn(e.detail.value as any)
                  }
                  value={distanceToSearchIn}
                >
                  <IonIcon slot="start" icon={navigateCircle} />
                  <p slot="end">{distanceToSearchIn} km</p>
                </IonRange>
              </IonItem>
              <IonListHeader className="ion-text-wrap">
                Search within Price Range
              </IonListHeader>
              <IonItem>
                <IonRange
                  min={0}
                  max={1000}
                  step={25}
                  snaps={true}
                  ticks={true}
                  dualKnobs={true}
                  color="success"
                  onIonChange={(e) => {
                    let range = e.detail.value as any;
                    setMinPrice(range.lower < 1000 ? range.lower : 999);
                    setMaxPrice(range.upper < 1000 ? range.upper : undefined);
                  }}
                  value={{ lower: minPrice, upper: maxPrice ? maxPrice : 1000 }}
                >
                  <p slot="start">Rs. {minPrice}</p>
                  <p slot="end">{maxPrice ? "Rs. " + maxPrice : "Any"}</p>
                </IonRange>
              </IonItem>
              <IonListHeader>Select Categories to Search in</IonListHeader>
              <IonItem>
                <IonList>
                  {[
                    { val: "Shop", isChecked: categories.includes("Shop") },
                    { val: "Salon", isChecked: categories.includes("Salon") },
                    { val: "Clinic", isChecked: categories.includes("Clinic") },
                    {
                      val: "Restaurant",
                      isChecked: categories.includes("Restaurant"),
                    },
                    {
                      val: "Service Center",
                      isChecked: categories.includes("Service Center"),
                    },
                    {
                      val: "Workshop",
                      isChecked: categories.includes("Workshop"),
                    },
                  ].map(({ val, isChecked }, i) => (
                    <IonItem key={i} lines="none">
                      <IonLabel>{val}</IonLabel>
                      <IonCheckbox
                        slot="start"
                        value={val}
                        checked={isChecked}
                        onIonChange={(e) => {
                          let prevCats = categories;
                          if (e.detail.checked) prevCats.push(e.detail.value);
                          else
                            prevCats.splice(
                              prevCats.indexOf(e.detail.value),
                              1
                            );
                          setCategories(prevCats);
                        }}
                      />
                    </IonItem>
                  ))}
                </IonList>
              </IonItem>
              <IonListHeader>Set Maximum Queue Length</IonListHeader>
              <IonItem>
                <IonRange
                  min={5}
                  max={100}
                  step={5}
                  snaps={true}
                  ticks={true}
                  color="warning"
                  onIonChange={(e) =>
                    setMaxQueue(
                      e.detail.value < 100 ? (e.detail.value as any) : undefined
                    )
                  }
                  value={maxQueue ? maxQueue : 101}
                >
                  <IonIcon slot="start" icon={walk} />
                  <p slot="end">{maxQueue ? maxQueue : "Any"}</p>
                </IonRange>
              </IonItem>
              <IonItem lines="none" className="ion-padding-top">
                <IonButton
                  color="primary"
                  size="default"
                  onClick={() => {
                    setShowFilters(false);
                    getServices(undefined);
                  }}
                >
                  Apply Filters
                </IonButton>
              </IonItem>
            </IonList>
                </IonContent>*/}
      </IonMenu>
      <IonContent id="mainContainer">
        <div id="topSection">
          <IonSegment
            value={tabSection}
            onIonChange={(e) => setTabSection(e.detail.value!)}
          >
            <IonSegmentButton value="locations" style={{ "max-width": "none" }}>
              <IonIcon icon={locationOutline}></IonIcon>
              <IonLabel>Locations</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="products" style={{ "max-width": "none" }}>
              <IonIcon icon={cubeOutline}></IonIcon>
              <IonLabel>Products</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          <IonGrid>
            <IonRow>
              <IonCol style={{ maxWidth: "55px", maxHeight: "42px" }}>
                <IonMenuToggle auto-hide="false">
                  <IonButton
                    color="primary"
                    style={{ marginTop: "13px" }}
                    onClick={async () => await menuController.toggle()}
                  >
                    <IonIcon icon={optionsOutline}></IonIcon>
                  </IonButton>
                </IonMenuToggle>
              </IonCol>
              <IonCol>
                {vendorsData && (
                  <IonSearchbar
                    style={{ marginTop: "0" }}
                    value={searchText}
                    onIonChange={(e) => {
                      setSearchText(e.detail.value!);
                    }}
                    className="ion-margin-top"
                    animated
                    enterkeyhint="search"
                    placeholder={"Search for " + tabSection}
                  ></IonSearchbar>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        <Map />

        <div id="contentForMenu">
          {tabSection === "locations" ? (
            <>
              <div id="locationsSections">
                <div style={{ pointerEvents: "all" }}>
                  <IonLabel className="eina ion-text-wrap" color="light">
                    <h2 className="eina">Stores near your location</h2>
                  </IonLabel>

                  {vendorsData.map((vendor, i) => {
                    if (
                      [
                        vendor.name,
                        vendor.category,
                        JSON.stringify(vendor.serviceData),
                        vendor.description,
                        vendor.summary,
                        vendor.email,
                        vendor.phone,
                        vendor.type,
                        vendor.location.name,
                        vendor.weblink,
                      ]
                        .join(" ")
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase()) > -1
                    ) {
                      // let temp = selectedVendorCoords;
                      // temp.push({latlng: vendor.location.coords, name: vendor.name});
                      // setSelectedVendorCoords(temp);
                      //console.log(i);

                      return (
                        <IonCard
                          key={i}
                          className="locationCard"
                          onClick={() => history.push("/vendor/" + vendor.id)}
                        >
                          <div
                            className="cardImage"
                            style={{
                              background:
                                "url(https://firebasestorage.googleapis.com/v0/b/ecogo-business.appspot.com/o/" +
                                vendor.images.other[0] +
                                "?alt=media&token=37f81d7c-71f6-47e8-8585-376ee625e638)",
                            }}
                          ></div>
                          <div className="cardContent">
                            <IonCardHeader>
                              <IonCardTitle className="eina">
                                {vendor.name}
                              </IonCardTitle>

                              <IonCardSubtitle>
                                {vendor.summary}
                              </IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent>
                              <IonCardSubtitle>
                                {vendor.location.name}
                              </IonCardSubtitle>
                              {/*<IonCardSubtitle color="dark">
                    302 Views | <IonIcon icon={star}></IonIcon>
                    <IonIcon icon={star}></IonIcon>
                    <IonIcon icon={star}></IonIcon>
                    <IonIcon icon={starHalf}></IonIcon>
                    <IonIcon icon={starOutline}></IonIcon>
                </IonCardSubtitle>*/}
                            </IonCardContent>
                          </div>
                        </IonCard>
                      );
                    } else return <></>;
                  })}
                </div>
              </div>
            </>
          ) : null}
          {tabSection === "products" ? (
            <>
              <IonLabel className="eina ion-text-wrap">
                <h2 className="eina">
                  Products from stores near your location
                </h2>
              </IonLabel>
              {vendorsData.map((vendor, i) => {
                return (
                  <>
                    {Object.keys(vendor.serviceData).map((k: any, n) => {
                      if (
                        [
                          vendor.name,
                          vendor.category,
                          JSON.stringify(vendor.serviceData),
                          vendor.description,
                          vendor.summary,
                          vendor.email,
                          vendor.phone,
                          vendor.type,
                          vendor.location.name,
                          vendor.weblink,
                          vendor.serviceData[k].description,
                          vendor.serviceData[k].name,
                          vendor.serviceData[k].originalPrice,
                          vendor.serviceData[k].price,
                          vendor.serviceData[k].category.join(" "),
                          vendor.serviceData[k].variants.join(" "),
                        ]
                          .join(" ")
                          .toLowerCase()
                          .indexOf(searchText.toLowerCase()) > -1
                      )
                        return (
                          <IonCard
                            className="productCard"
                            //When userclicks on a productCard, they are led to the product page.
                            onClick={() =>
                              //Enabled product view page. The click listener sends the user to the product page.
                              {
                                history.push("/service/" + vendor.id + "/" + k);
                              }
                            }
                          >
                            <div
                              className="cardImage"
                              style={{
                                background:
                                  "url(https://firebasestorage.googleapis.com/v0/b/ecogo-business.appspot.com/o/" +
                                  vendor.serviceData[k].images[0] +
                                  "?alt=media&token=37f81d7c-71f6-47e8-8585-376ee625e638)",
                              }}
                            ></div>
                            <div className="cardContent">
                              <IonCardHeader>
                                <IonCardTitle className="eina">
                                  {vendor.serviceData[k].name}
                                </IonCardTitle>

                                <IonCardSubtitle>{vendor.name}</IonCardSubtitle>
                              </IonCardHeader>
                              <IonCardContent>
                                <IonCardSubtitle color="dark">
                                  {/* 302 Views | <IonIcon icon={star}></IonIcon>
                                  <IonIcon icon={star}></IonIcon>
                                  <IonIcon icon={star}></IonIcon>
                                  <IonIcon icon={starHalf}></IonIcon>
                                  <IonIcon icon={starOutline}></IonIcon> */}
                                </IonCardSubtitle>
                                <IonCardTitle className="eina">
                                  {"HK$ "}
                                  {vendor.serviceData[k].isDiscounted ? (
                                    <span
                                      style={{
                                        color: "black",
                                        textDecoration: "line-through",
                                      }}
                                    >
                                      <span style={{ color: "red" }}>
                                        {vendor.serviceData[k].originalPrice}
                                      </span>
                                    </span>
                                  ) : null}{" "}
                                  {vendor.serviceData[k].price}
                                </IonCardTitle>
                              </IonCardContent>
                            </div>
                          </IonCard>
                        );
                      else return <></>;
                    })}
                  </>
                );
              })}
            </>
          ) : null}

          <>
            {/*<IonCard
                className="productCard"
                onClick={() => history.push("/service/" + 101 + "/" + 0)}
              >
                <div
                  className="cardImage"
                  style={{
                    background:
                      "url(https://media.istockphoto.com/photos/closeup-white-or-light-grey-colors-fabric-sample-texturelight-grey-picture-id1131975692?s=612x612)",
                  }}
                ></div>
                <div className="cardContent">
                  <IonCardHeader>
                    <IonCardTitle className="eina">
                      Real Strawberry and Hibiscus
                    </IonCardTitle>

                    <IonCardSubtitle>Rebooked</IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonCardSubtitle color="dark">
                      302 Views | <IonIcon icon={star}></IonIcon>
                      <IonIcon icon={star}></IonIcon>
                      <IonIcon icon={star}></IonIcon>
                      <IonIcon icon={starHalf}></IonIcon>
                      <IonIcon icon={starOutline}></IonIcon>
                    </IonCardSubtitle>
                    <IonCardTitle className="eina">HK$ 7.99</IonCardTitle>
                  </IonCardContent>
                </div>
                </IonCard>*/}
            {/* /  </>
          ) : null}
        </div> */}
            {/*{vendorsData.map((vendor, i) => (
          <IonList
            key={i}
            style={{ border: "1px solid #ddd" }}
            className="ion-margin-top"
          >
            <IonListHeader className="ion-padding-right">
              <IonLabel>
                <span className="eina">{vendor.name}</span>

                <p>{vendor.category}</p>
              </IonLabel>
              <IonButton
                size="small"
                fill="solid"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  history.push("/vendor/" + vendor.id);
                }}
              >
                More
                <IonIcon icon={information} slot="start"></IonIcon>
              </IonButton>
            </IonListHeader>
            {Object.keys(vendor.serviceData)
              .reverse()
              .map((key: any) =>
                [
                  vendor.name,
                  vendor.category,
                  vendor.serviceData[key].name,
                  vendor.serviceData[key].description,
                  vendor.serviceData[key],
                ]
                  .join(" ")
                  .toLowerCase()
                  .indexOf(searchText.toLowerCase()) > -1 ? (
                  <IonCard
                    key={key}
                    className="serviceCard"
                    onClick={() =>
                      history.push("/service/" + vendor.id + "/" + key)
                    }
                  >
                    <IonCardHeader>
                      <IonItem lines="none" style={{ padding: "0" }}>
                        <IonCardTitle>
                          {vendor.serviceData[key].name}
                        </IonCardTitle>
                      </IonItem>

                      <IonCardSubtitle>
                        <IonIcon icon={pricetag}></IonIcon>{" "}
                        {!vendor.serviceData[key].isGeneral &&
                        vendor.serviceData[key].isDiscounted ? (
                          <span
                            style={{
                              color: "black",
                              textDecoration: "line-through",
                            }}
                          >
                            <span style={{ color: "red" }}>
                              {"Rs. " +
                                vendor.serviceData[key].originalPrice +
                                " "}
                            </span>
                          </span>
                        ) : null}
                        {vendor.serviceData[key].isGeneral
                          ? "Price may vary"
                          : "Rs. " + vendor.serviceData[key].price}
                      </IonCardSubtitle>
                      <IonCardSubtitle>
                        <IonIcon icon={hourglass}></IonIcon>{" "}
                        {vendor.serviceData[key].isGeneral
                          ? "Duration may vary"
                          : vendor.serviceData[key].duration + " minutes"}
                      </IonCardSubtitle>
                      {vendor.serviceData[key].rating ? (
                        <IonCardSubtitle>
                          {Array(
                            Math.floor(average(vendor.serviceData[key].rating))
                          ).fill(
                            <IonIcon icon={star} color="warning"></IonIcon>
                          )}
                          {average(vendor.serviceData[key].rating) -
                            Math.floor(
                              average(vendor.serviceData[key].rating)
                            ) >=
                          0.4 ? (
                            <IonIcon icon={starHalf} color="warning"></IonIcon>
                          ) : null}
                        </IonCardSubtitle>
                      ) : null}
                    </IonCardHeader>
                    <IonCardContent>
                      <p>
                        <b>{vendor.serviceData[key].description}</b>
                      </p>
                      <p style={{ color: "#dd0000" }}>
                        Current Queue: <b>{vendor.serviceData[key].queue}</b>
                      </p>
                      <p style={{ fontSize: "10px" }}>
                        Click to view details and enter the queue.
                      </p>
                    </IonCardContent>
                  </IonCard>
                ) : null
              )}
          </IonList>
                          ))} */}
          </>
        </div>
      </IonContent>
    </IonApp>
  );
};

export default Explore;
