import { Geolocation } from "@ionic-native/geolocation";
import { coords } from "./myTypes";

export async function getLocation() {
  try {
    const position = await Geolocation.getCurrentPosition();
    return {
      lng: position.coords.longitude,
      lat: position.coords.latitude,
    };
  } catch (e) {
    return false;
  }
}

export async function getCoordinatesFromDistance(
  center: coords,
  radius: Number
) {
  /** adapted from @stparham at https://stackoverflow.com/questions/46630507/how-to-run-a-geo-nearby-query-with-firestore */
  const earth_radius = 6378.1; //km
  const KM_PER_DEGREE_LATITUDE = 40075 / 360;
  const latDegrees = +radius / KM_PER_DEGREE_LATITUDE;
  const latitudeNorth = Math.min(90, +center.lat! + latDegrees);
  const latitudeSouth = Math.max(-90, +center.lat! - latDegrees);

  const circumference_at_currentLat =
    2 * Math.PI * earth_radius * Math.cos(+center.lat!);
  const long_KM_PER_DEG = circumference_at_currentLat / 360;

  const longDegrees = +radius / long_KM_PER_DEG;

  return {
    swCorner: {
      // bottom-left (SW corner)
      lat: latitudeSouth,
      lng: wrapLongitude(+center.lng! + longDegrees),
    },
    neCorner: {
      // top-right (NE corner)
      lat: latitudeNorth,
      lng: wrapLongitude(+center.lng! - longDegrees),
    },
  };
}

function wrapLongitude(longitude: number) {
  /** adapted from @stparham at https://stackoverflow.com/questions/46630507/how-to-run-a-geo-nearby-query-with-firestore */

  if (longitude <= 180 && longitude >= -180) {
    return longitude;
  }
  const adjusted = longitude + 180;
  if (adjusted > 0) {
    return (adjusted % 360) - 180;
  }
  // else
  return 180 - (-adjusted % 360);
}
