import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { coords, vendor, Queue, Notif } from "./myTypes";
import { getCoordinatesFromDistance } from "./geolocation";
import { db } from "./firestoreconfig";

var firebaseConfigVendor = {
  apiKey: "AIzaSyA33tdQbajLfXibOfMZ6GK6N7DG1W8hTnI",
  authDomain: "ecogo-business.firebaseapp.com",
  projectId: "ecogo-business",
  storageBucket: "ecogo-business.appspot.com",
  messagingSenderId: "999593576503",
  appId: "1:999593576503:web:f045f6d5444136cc9a97b2",
  measurementId: "G-1MXY0HWE0K"
};

const firebaseVendor = firebase.initializeApp(firebaseConfigVendor, "vendor");
const vendorDb = firebaseVendor.firestore();

export async function getServiceData(
  location: coords,
  distance: number,
  minPrice: number,
  maxPrice: number | undefined,
  categories: Array<String>,
  maxQueue: number | undefined
) {
  const boundCoordinates = await getCoordinatesFromDistance(location, distance);
  var categoriesList = categories.slice(0);
  categoriesList.push("NULL");

  return (
    vendorDb
      .collection("vendors")
      .where("location.coords.lat", ">=", boundCoordinates.swCorner.lat)
      .where("location.coords.lat", "<=", boundCoordinates.neCorner.lat)
      //query longitudes later
      //.where("category", "in", categoriesList)
      .get()
      .then(function (querySnapshot) {
        let response: Array<vendor> = [];
        querySnapshot.forEach(function (doc) {

          let vendorData = doc.data();
          vendorData.id = doc.id;

          if (
            vendorData.location.coords.lng >= boundCoordinates.swCorner.lng &&
            vendorData.location.coords.lng <= boundCoordinates.neCorner.lng
          ) {
            Object.keys(vendorData.serviceData).forEach((key, i) => {
              if (!vendorData.serviceData[key].queue)
                vendorData.serviceData[key].queue = 0;

              let validationCondition;
              if (vendorData.serviceData[key].isGeneral) {
                validationCondition =
                  vendorData.serviceData[key].queue <=
                    (maxQueue ? maxQueue : Infinity) &&
                  vendorData.serviceData[key].available;
              } else {
                validationCondition =
                  vendorData.serviceData[key].price >= minPrice &&
                  vendorData.serviceData[key].price <=
                    (maxPrice ? maxPrice : Infinity) &&
                  vendorData.serviceData[key].queue <=
                    (maxQueue ? maxQueue : Infinity) &&
                  vendorData.serviceData[key].available;
              }

              if (!validationCondition) {
                delete vendorData.serviceData[key];
              }
            });

            vendorData.distance = Math.sqrt((vendorData.location.coords.lng - (location.lng! as number))^2 + (vendorData.location.coords.lat - (location.lat! as number))^2);

              response.push(vendorData as vendor);
            
          }
        });
        response.sort((a, b) => a.distance - b.distance);
        return response;
      })
      .catch(function (error) {
        return [];
      })
  );
}

export async function getVendorData(id: string) {
  return vendorDb
    .collection("vendors")
    .doc(id)
    .get()
    .then(function (doc) {
      if (doc.exists) {
        let vendorData = doc.data()!;
        vendorData.id = doc.id;

        Object.keys(vendorData.serviceData).forEach((key, i) => {
          if (!vendorData.serviceData[key].queue)
            vendorData.serviceData[key].queue = 0;

          if (!vendorData.serviceData[key].available) {
            delete vendorData.serviceData[key];
          }
        });

        return vendorData;
      } else {
        return null;
      }
    })
    .catch(function (error) {
      return null;
    });
}

export async function requestEntryToQueue(
  vendorData: vendor,
  serviceID: number
) {
  return vendorDb
    .collection("vendors")
    .doc(vendorData.id)
    .collection("notifications")
    .add({
      service: vendorData.serviceData[serviceID],
      serviceID: serviceID,
      time: firebase.firestore.Timestamp.fromDate(new Date()),
      type: "request",
      userData: {
        name: firebase.auth().currentUser!.displayName,
        email: firebase.auth().currentUser!.email,
        id: firebase.auth().currentUser!.uid,
      },
      active: true,
    })
    .then((doc) => {
      return doc.id;
    })
    .catch((e) => {
      return false;
    });
}

export async function checkIfAlreadyInQueue(
  vendorID: string,
  serviceID: number
) {
  return vendorDb
    .collection("queues")
    .doc(vendorID + "_" + serviceID)
    .collection("customers")
    .where("isQueued", "==", true)
    .where("userData.id", "==", firebase.auth().currentUser!.uid)
    .get()
    .then((querySnapshot) => {
      var i = 0;
      querySnapshot.forEach(function (doc) {
        i++;
      });

      return i !== 0;
    });
}

export async function getQueueStatus(vendorID: string, serviceID: number) {
  return vendorDb
    .collection("queues")
    .doc(vendorID + "_" + serviceID)
    .collection("customers")
    .where("isQueued", "==", true)
    .orderBy("time", "asc")
    .get()
    .then(function (querySnapshot) {
      var i = 1; //for total number of people in queue
      var queueObj: Queue | any;
      querySnapshot.forEach(function (doc) {
        if (doc.data().userData.id === firebase.auth().currentUser!.uid) {
          queueObj = doc.data() as any;
          queueObj.rank = i;
          queueObj.id = doc.id;
        }
        i++;
      });
      if (queueObj === undefined) {
        queueObj = {
          isQueued: false,
        };
      }
      queueObj.total = i;
      return queueObj;
    });
}

export async function checkOutFromService(
  checkOutNotif: Notif,
  serviceRating: number
) {
  return db
    .collection("users")
    .doc(firebase.auth().currentUser!.uid)
    .collection("notifications")
    .doc(checkOutNotif.id)
    .set({ checkedOut: true }, { merge: true })
    .then(() => {
      if (serviceRating !== 0) {
        return vendorDb
          .collection("vendors")
          .doc(checkOutNotif.vendor.id)
          .update({
            ["serviceData." +
            checkOutNotif.serviceID +
            ".rating"]: firebase.firestore.FieldValue.arrayUnion(serviceRating),
          })
          .then(() => {
            return vendorDb
              .collection("vendors")
              .doc(checkOutNotif.vendor.id)
              .collection("notifications")
              .add({
                service: checkOutNotif.service,
                serviceID: checkOutNotif.serviceID,
                time: firebase.firestore.Timestamp.fromDate(new Date()),
                type: "rating",
                userData: {
                  name: firebase.auth().currentUser!.displayName,
                  email: firebase.auth().currentUser!.email,
                  id: firebase.auth().currentUser!.uid,
                },
                ratingValue: serviceRating,
              })
              .then(() => {
                return true;
              });
          });
      } else {
        return true;
      }
    });
}
