export async function getBase64FromUrl(url: string, src: string) {
  url =
    "https://firebasestorage.googleapis.com/v0/b/" +
    src +
    ".appspot.com/o/" +
    url +
    "?alt=media&token=37f81d7c-71f6-47e8-8585-376ee625e638";
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
}

export function imgfy(u: string, src = "ecogo-business") {
  return (
    "https://firebasestorage.googleapis.com/v0/b/" +
    src +
    ".appspot.com/o/" +
    u +
    "?alt=media&token=37f81d7c-71f6-47e8-8585-376ee625e638"
  );
}
