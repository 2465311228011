import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonButtons,
  IonBackButton,
} from "@ionic/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import { toast } from "../toast";
import { signupUser } from "../firebaseconfig";
import { useDispatch } from "react-redux";
import { setUserState } from "../redux/actions";

const Signup: React.FC = () => {
  const [busy, setBusy] = useState<boolean>(false);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [rPassword, setrPassword] = useState<string>("");
  const [fullname, setFullname] = useState<string>("");

  const dispatch = useDispatch();

  async function signupResponse() {
    if (
      email.trim() === "" ||
      password.trim() === "" ||
      fullname.trim() === "" ||
      rPassword.trim() === ""
    ) {
      return toast("All fields are mandatory.", 5000, "danger");
    }
    if (password !== rPassword) {
      return toast("Passwords must match", 5000, "danger");
    }
    setBusy(true);
    const res: any = await signupUser(fullname, email, rPassword);
    if (res) {
      dispatch(setUserState(res.user));
      window.location.reload();
      //history.replace('/dashboard');
    } else {
      // error occured
    }
    setBusy(false);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle className="eina">SignUp</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonLoading isOpen={busy} message={"Please wait."} spinner="dots" />
      <IonContent className="ion-padding">
        <IonList>
          <IonItem>
            <IonLabel position="floating">Full Name</IonLabel>
            <IonInput
              type="text"
              onIonChange={(e: any) => setFullname(e.target.value)}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Email Address</IonLabel>
            <IonInput
              type="email"
              onIonChange={(e: any) => setEmail(e.target.value)}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Password</IonLabel>
            <IonInput
              type="password"
              onIonChange={(e: any) => setPassword(e.target.value)}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Confirm Password</IonLabel>
            <IonInput
              type="password"
              onIonChange={(e: any) => setrPassword(e.target.value)}
            />
          </IonItem>
        </IonList>
        <IonButton
          color="primary"
          onClick={() => signupResponse()}
          className="ion-margin-top"
        >
          SignUp
        </IonButton>
        <p>
          Already have an account? <Link to="/login">Login</Link>
        </p>
        <div className="ion-text-center">
          <img
            alt=""
            src={require("../images/misc/signup.png").default}
            style={{ maxWidth: "300px" }}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Signup;
