/// <reference types="@types/google.maps" />
import {
  IonPage,
  IonIcon,
  IonLabel,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonSegment,
  IonSegmentButton,
  IonFooter,
  IonContent,
  IonButton,
  IonApp,
  IonModal,
  IonList,
  IonItem,
  IonInput,
} from "@ionic/react";
import React, { useState } from "react";
import Explore from "../components/explore";
import {
  arrowBack,
  compassOutline,
  earthOutline,
  personCircleOutline,
  cartOutline,
  locateOutline,
} from "ionicons/icons";
import Community from "../components/community";
import Shop from "../components/shop";
import Profile from "../components/profile";
import { db } from "../firestoreconfig";
import { useSelector } from "react-redux";
import { toast } from "../toast";
import "../theme/dashboard.css";
import { getLocation } from "../geolocation";
import { coords, MyLocation } from "../myTypes";
import { Loader } from "@googlemaps/js-api-loader";

const loader = new Loader({
  apiKey: "AIzaSyAkwRNpKQ8-EfvObWvWStBgL4B6Mj9qQ6E",
  libraries: ["places",],
});

loader.load();

const Dashboard: React.FC = (props) => {
  const [tabSection, SetTabSection] = useState<string>("search");
  const [shouldCheckProps, setShouldCheckProps] = useState<boolean>(true);
  
  const [showLocationModal, setShowLocationModal] = useState<boolean>(false);
  const [locationCoords, setLocationCoords] = useState<coords>();
  const [selectLocationCoords, setSelectLocationCoords] = useState<coords>();
  const [locationSuggest, setLocationSuggest] = useState<Array<MyLocation>>([]);
  const [selectLocation, setSelectLocation] = useState<string>("");
  const [lastSelectLocation, setLastSelectLocation] = useState<string>("");

  if (shouldCheckProps) {
    if ((props as any).match.params.page !== undefined) {
      SetTabSection((props as any).match.params.page);
      window.history.pushState({}, "", "/dashboard");
      setShouldCheckProps(false);
    }
  }

  const user = useSelector((state: any) => state.user.data);
  const [newAlerts, setNewAlerts] = useState<boolean>(false);

  //one-time render stuff
  const [renderIndex, setRenderIndex] = useState<number>(0);

  if (renderIndex < 1) {
    getCurrentLocation();
    var sessionToken = new google.maps.places.AutocompleteSessionToken();
    if (user.email === "guest@ecogohk.org") {
      toast("You are using EcoGo in Guest Mode.", 5000, "warning");
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var liveNotifCheck = db
      .collection("users")
      .doc(user.uid)
      .collection("notifications")
      .where("time", ">=", new Date(new Date().getTime() - 60000))
      .onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          if (window.location.pathname === "/dashboard") {
            if (change.type === "added") {
              if (change.doc.data().type === "service") {
                toast(
                  change.doc.data().vendor.name +
                    " " +
                    (change.doc.data().didAccept ? "accepted" : "rejected") +
                    " your entry to their queue for " +
                    change.doc.data().service.name +
                    ".",
                  10000,
                  "dark",
                  [
                    {
                      side: "end",
                      text: "View",
                      handler: () => {
                        SetTabSection("community");
                        setNewAlerts(false);
                      },
                    },
                  ]
                );
              }
              setNewAlerts(true);
            }
          }
        });
      });
    setRenderIndex(renderIndex + 1);
  }
  //

  async function getCurrentLocation() {
    if (
      localStorage.getItem("location") !== null &&
      localStorage.getItem("location") !== ""
    ) {
      let locR = JSON.parse(localStorage.getItem("location")!);
      setLocationCoords(locR);
    } else {
      await getLocation().then(function (loc) {
        if (loc) {
          toast("Detected current location successfully.", 2000, "success");
          setLocationCoords(loc);
        } else {
          toast("Could not detect or retrieve location.", 2000, "danger");
          setShowLocationModal(true);
        }
      });
    }
  }

  function saveLocation() {
    if (selectLocation === "" || selectLocationCoords == null) {
      toast("You must enter a location to continue.", 2000, "danger");
    } else {
      toast("Location selected.", 2000, "success");
      localStorage.setItem("location", JSON.stringify(selectLocationCoords));
      setShowLocationModal(false);
      setLocationCoords(selectLocationCoords);
    }
  }

  // async function onlyGeocode() {
  //   let str = selectLocation.replace(";", "");
  //   if (str.trim() !== "") {
  //     var xhttp = new XMLHttpRequest();
  //     xhttp.onreadystatechange = function () {
  //       if (this.readyState === 4 && this.status === 200) {
  //         JSON.parse(this.responseText).features.forEach((e: any) => {
  //           setSelectLocationCoords({
  //             lat: e.center[1],
  //             lng: e.center[0],
  //           });
  //         });
  //       }
  //     };
  //     xhttp.open(
  //       "GET",
  //       "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
  //         str +
  //         ".json?limit=1&country=HK&access_token=pk.eyJ1IjoibXJpZ2Fua3Bhd2FnaSIsImEiOiJjazl1OXk4c2swaTR4M2VtdXVrZmZjYnFtIn0.vnnewjnm1e6fNdWJcKoCfA",
  //       true
  //     );
  //     xhttp.send();
  //   }
  // }

  function geocode() {
    if (Math.abs(selectLocation.length - lastSelectLocation.length) > 1) {
      let str = selectLocation.replace(";", "");
      setLastSelectLocation(str);
      if (str.trim() !== "") {
        new google.maps.places.AutocompleteService().getPlacePredictions(
          {
            input: str,
            sessionToken: sessionToken,
            componentRestrictions: { country: "hk" },
          },
          (res) => {
            if (res) {
              let response: Array<MyLocation> = [];
              res!.forEach((e: any) => {
                response.push({
                  name: e.description,
                  lat: undefined,
                  lng: undefined,
                  id: e.place_id,
                });
              });
              setLocationSuggest(response);
            }
          }
        );
      }
    }
  }

  async function placeDetail(id: string) {
    new google.maps.places.PlacesService(
      document.createElement("div")
    ).getDetails(
      {
        placeId: id,
        sessionToken: sessionToken,
        fields: ["geometry"],
      },
      (res) => {
        setSelectLocationCoords({
          lat: res!.geometry!.location!.lat(),
          lng: res!.geometry!.location!.lng(),
        });
        sessionToken = new google.maps.places.AutocompleteSessionToken();
      }
    );
  }

  return (
    <IonApp>
      <IonModal isOpen={showLocationModal} backdropDismiss={false}>
        <IonToolbar color="clear">
          <IonButton
            slot="start"
            onClick={() => setShowLocationModal(false)}
            fill="clear"
            hidden={locationCoords === undefined}
          >
            <IonIcon icon={arrowBack}></IonIcon>
          </IonButton>
          <IonTitle className="eina">Select Location</IonTitle>
        </IonToolbar>
        <IonContent className="ion-padding">
          {locationCoords !== undefined
            ? "Select your location manually."
            : "We could not detect your location. Enter your current location to continue."}
          <IonList>
            <IonItem>
              <IonLabel position="floating">Your Location</IonLabel>
              <IonInput
                value={selectLocation}
                onIonChange={(e: any) => {
                  setSelectLocation(e.target.value);
                  geocode();
                }}
                type="text"
                // onIonBlur={() => {
                //   if (
                //     selectLocation.trim() !== "" &&
                //     selectLocationCoords == null
                //   ) {
                //     onlyGeocode();
                //   }
                // }}
              />
            </IonItem>
            {locationSuggest.map((place, i) => (
              <IonItem
                button={true}
                detail={true}
                onClick={() => {
                  setSelectLocation(place.name);
                  if (place.lat && place.lng) {
                    setSelectLocationCoords({
                      lat: place.lat,
                      lng: place.lng,
                    });
                  } else if (place.id) {
                    placeDetail(place.id);
                  }
                }}
                key={i}
              >
                {place.name}
              </IonItem>
            ))}
            <IonItem lines="none">
              {selectLocationCoords ? (
                // <Map
                //   // eslint-disable-next-line react/style-prop-object
                //   style={"mapbox://styles/mapbox/streets-v9"}
                //   containerStyle={{
                //     height: "200px",
                //     width: "100%",
                //   }}
                //   center={[
                //     Number(selectLocationCoords.lng),
                //     Number(selectLocationCoords.lat),
                //   ]}
                //   zoom={[15]}
                // >
                //   <Marker
                //     coordinates={[
                //       +selectLocationCoords.lng!,
                //       +selectLocationCoords.lat!,
                //     ]}
                //     anchor="bottom"
                //   >
                //     <img
                //       alt=""
                //       src={require("../images/markers/primary.png").default}
                //     />
                //   </Marker>
                // </Map>
                <iframe
                  style={{ width: "100%", border: "0" }}
                  src={
                    "https://www.google.com/maps/embed/v1/place?key=AIzaSyAkwRNpKQ8-EfvObWvWStBgL4B6Mj9qQ6E&q=" +
                    selectLocationCoords.lat +
                    "," +
                    selectLocationCoords.lng
                  }
                  title="Map"
                ></iframe>
              ) : null}
            </IonItem>

            <IonButton color="primary" onClick={() => saveLocation()}>
              Save Location
            </IonButton>
          </IonList>
        </IonContent>
      </IonModal>
      <IonPage>
        <IonHeader translucent>
          <IonToolbar color="primary">
            <IonTitle className="eina">EcoGo</IonTitle>
            <IonButton
              color="clear"
              slot="end"
              onClick={() => setShowLocationModal(true)}
              style={{ marginRight: "20px" }}
            >
              <IonIcon icon={locateOutline}></IonIcon>
            </IonButton>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          {tabSection === "search" ? (
            <Explore
              locationCoords={locationCoords}
              key={JSON.stringify(locationCoords)}
            />
          ) : null}
          {tabSection === "community" ? <Community /> : null}
          {tabSection === "profile" ? <Profile /> : null}
          {tabSection === "shop" ? <Shop /> : null}
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonSegment
              value={tabSection}
              onIonChange={(e: any) => SetTabSection(e.target.value!)}
            >
              <IonSegmentButton value="search">
                <IonIcon icon={compassOutline}></IonIcon>
                <IonLabel className="ion-text-capitalize">Explore</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton
                value="community"
                color={newAlerts ? "danger" : "default"}
              >
                <IonIcon icon={earthOutline}></IonIcon>
                <IonLabel className="ion-text-capitalize">Community</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="profile">
                <IonIcon icon={personCircleOutline}></IonIcon>
                <IonLabel className="ion-text-capitalize">Profile</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="shop">
                <IonIcon icon={cartOutline}></IonIcon>
                <IonLabel className="ion-text-capitalize">Shop</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    </IonApp>
  );
};

export default Dashboard;
