import React, { useState } from "react";
import {
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonLoading,
  IonLabel,
  IonList,
  IonItem,
  IonIcon,
  IonModal,
  IonInput,
  // IonPage,
  // IonGrid,
  // IonCol,
  // IonRow,
  // IonSegment,
  // IonSegmentButton,
  // IonCard,
  // IonCardContent,
  // IonCardHeader,
  // IonCardSubtitle,
  // IonCardTitle,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  logoutUser,
  resetPassword,
  changeDisplayName,
} from "../firebaseconfig";
import { pencil, 
  arrowBack, 
  // star 
} from "ionicons/icons";
import AboutContent from "./aboutContent";
import "../theme/profile.css";

const Profile: React.FC = () => {
  const [showNameChangeModal, setShowNameChangeModal] = useState(false);
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [busy, setBusy] = useState<boolean>(false);
  const user = useSelector((state: any) => state.user.data);
  const history = useHistory();

  const [username, setUsername] = useState<string>(user.displayName);
  const [newName, setNewName] = useState<string>(user.displayName);

  async function logoutResponse() {
    await logoutUser();
    history.replace("/");
  }

  async function changePassword() {
    setBusy(true);
    const res = await resetPassword(user.email);
    if (res) {
    } else {
    }
    setBusy(false);
  }

  async function changeName() {
    setBusy(true);
    const res = await changeDisplayName(newName);
    if (res) {
      setShowNameChangeModal(false);
      setUsername(newName);
    } else {
    }
    setBusy(false);
  }

/*   const [profileSection, setProfileSection] = useState<
    "review" | "friends" | "bookmarks"
  >("review"); */

  return (
    <div>
      <>
        <>
          {/* <div id="profileBackground">
      <div id="profileContainer">
        <IonGrid class="top">
          <IonRow>
            <IonCol>
              <b>391</b>
              <br />
              Reviews
            </IonCol>
            <IonCol>
              <img src={require("../images/icon.png").default} />
            </IonCol>
            <IonCol>
              <b>43</b>
              <br />
              Friends
            </IonCol>
          </IonRow>
        </IonGrid>

        <h3 className="center" style={{ fontWeight: 600 }}>
          Someone else
        </h3>
        <p className="center">Been green since 2017</p>

        <IonButton id="sendRequest">Send Friend Request</IonButton>
        <br />
        <IonSegment
          onIonChange={(e: any) => {
            setProfileSection(e.detail.value);
          }}
          defaultValue="review"
        >
          <IonSegmentButton value="review">
            <IonLabel>Reviews (24)</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="friends">
            <IonLabel>Friends</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="bookmarks">
            <IonLabel>Bookmarked</IonLabel>
          </IonSegmentButton>
        </IonSegment>

        {profileSection === "review" ? (
          <>
            <div id="profileCardContainer">
              <IonCard class="profileCard">
                <IonCardHeader>
                  <IonCardTitle>Rebooked HK</IonCardTitle>
                  <IonCardSubtitle>
                    <IonIcon icon={star} />
                    <IonIcon icon={star} />
                    <IonIcon icon={star} />
                    <IonIcon icon={star} />
                    <IonIcon icon={star} />
                  </IonCardSubtitle>
                </IonCardHeader>

                <IonCardContent>
                <p>Keep close to Nature's heart... and break clear away, once in
                  awhile, and climb a mountain or spend a week in the woods.
                  Wash your spirit clean.Keep close to Nature's heart... and break clear away, once in
                  awhile, and climb a mountain or spend a week in the woods.
                  Wash your spirit clean.Keep close to Nature's heart... and break clear away, once in
                  awhile, and climb a mountain or spend a week in the woods.
                  Wash your spirit clean.</p>
                </IonCardContent>
              </IonCard>
              <IonCard class="profileCard">
                <IonCardHeader>
                  <IonCardTitle>Rebooked HK</IonCardTitle>
                  <IonCardSubtitle>
                    <IonIcon icon={star} />
                    <IonIcon icon={star} />
                    <IonIcon icon={star} />
                    <IonIcon icon={star} />
                    <IonIcon icon={star} />
                  </IonCardSubtitle>
                </IonCardHeader>

                <IonCardContent>
                <p>Keep close to Nature's heart... and break clear away, once in
                  awhile, and climb a mountain or spend a week in the woods.
                  Wash your spirit clean.Keep close to Nature's heart... and break clear away, once in
                  awhile, and climb a mountain or spend a week in the woods.
                  Wash your spirit clean.Keep close to Nature's heart... and break clear away, once in
                  awhile, and climb a mountain or spend a week in the woods.
                  Wash your spirit clean.</p>
                </IonCardContent>
              </IonCard>
              <IonCard class="profileCard">
                <IonCardHeader>
                  <IonCardTitle>Rebooked HK</IonCardTitle>
                  <IonCardSubtitle>
                    <IonIcon icon={star} />
                    <IonIcon icon={star} />
                    <IonIcon icon={star} />
                    <IonIcon icon={star} />
                    <IonIcon icon={star} />
                  </IonCardSubtitle>
                </IonCardHeader>

                <IonCardContent>
                <p>Keep close to Nature's heart... and break clear away, once in
                  awhile, and climb a mountain or spend a week in the woods.
                  Wash your spirit clean.Keep close to Nature's heart... and break clear away, once in
                  awhile, and climb a mountain or spend a week in the woods.
                  Wash your spirit clean.Keep close to Nature's heart... and break clear away, once in
                  awhile, and climb a mountain or spend a week in the woods.
                  Wash your spirit clean.</p>
                </IonCardContent>
              </IonCard>
              <IonCard class="profileCard">
                <IonCardHeader>
                  <IonCardTitle>Rebooked HK</IonCardTitle>
                  <IonCardSubtitle>
                    <IonIcon icon={star} />
                    <IonIcon icon={star} />
                    <IonIcon icon={star} />
                    <IonIcon icon={star} />
                    <IonIcon icon={star} />
                  </IonCardSubtitle>
                </IonCardHeader>

                <IonCardContent>
                <p>Keep close to Nature's heart... and break clear away, once in
                  awhile, and climb a mountain or spend a week in the woods.
                  Wash your spirit clean.Keep close to Nature's heart... and break clear away, once in
                  awhile, and climb a mountain or spend a week in the woods.
                  Wash your spirit clean.Keep close to Nature's heart... and break clear away, once in
                  awhile, and climb a mountain or spend a week in the woods.
                  Wash your spirit clean.</p>
                </IonCardContent>
              </IonCard>
            </div>
          </>
        ) : null}
        {profileSection === "friends" ? <>FRIEND</> : null}
        {profileSection === "bookmarks" ? <>BOOKMARK</> : null}
      </div>

        <> */}
        </>
        <IonModal isOpen={showNameChangeModal} backdropDismiss={false}>
          <IonToolbar color="clear">
            <IonButton
              slot="start"
              onClick={() => setShowNameChangeModal(false)}
              fill="clear"
            >
              <IonIcon icon={arrowBack}></IonIcon>
            </IonButton>
            <IonTitle className="eina">Change Name</IonTitle>
          </IonToolbar>
          <IonContent className="ion-padding">
            <p>Enter your new name below.</p>
            <IonList>
              <IonItem>
                <IonLabel position="floating">Name</IonLabel>
                <IonInput
                  value={newName}
                  type="text"
                  onIonChange={(e: any) => setNewName(e.target.value)}
                />
              </IonItem>
            </IonList>
            <IonButton onClick={changeName} color="primary">
              Confirm Name
            </IonButton>
          </IonContent>
        </IonModal>
        <IonModal isOpen={showAboutModal} backdropDismiss={false}>
          <IonToolbar color="clear">
            <IonButton
              slot="start"
              onClick={() => setShowAboutModal(false)}
              fill="clear"
            >
              <IonIcon icon={arrowBack}></IonIcon>
            </IonButton>
            <IonTitle className="eina">About</IonTitle>
          </IonToolbar>
          <IonContent className="ion-padding">
            <AboutContent />
          </IonContent>
        </IonModal>
        <IonLoading isOpen={busy} message={"Please wait."} spinner="dots" />
        <IonList>
          <IonItem>
            <h2>{username}</h2>
            <IonButton
              hidden={user.email === "guest@ecogohk.org"}
              slot="end"
              onClick={() => setShowNameChangeModal(true)}
            >
              <IonLabel>Edit</IonLabel>
              <IonIcon icon={pencil}></IonIcon>
            </IonButton>
          </IonItem>
          <IonItem hidden={user.email === "guest@ecogohk.org"}>
            {user.email}
          </IonItem>
          <IonItem
            hidden={user.email === "guest@ecogohk.org"}
            button={true}
            onClick={changePassword}
            detail={true}
          >
            <IonLabel className="comfortaa">Change Password</IonLabel>
          </IonItem>
          <IonItem button={true} onClick={logoutResponse} detail={true}>
            <IonLabel color="danger" className="comfortaa">
              {user.email === "guest@ecogohk.org"
                ? "SignIn with your Account"
                : "Log Out"}
            </IonLabel>
          </IonItem>
          <IonItem onClick={() => setShowAboutModal(true)} button detail>
            About
          </IonItem>
          <IonItem
            onClick={() => window.open("mailto:info@ecogohk.org", "_system")}
            button
            detail
          >
            Contact Support
          </IonItem>
        </IonList>
      </>
    </div>
  );
};

export default Profile;
