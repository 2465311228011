import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonSkeletonText,
  IonItem,
  IonAvatar,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonAlert,
  IonLoading,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import React, { useState } from "react";
import "./Home.css";
import { vendor } from "../myTypes";
import { useHistory } from "react-router";
import {
  getVendorData,
  requestEntryToQueue,
  checkIfAlreadyInQueue,
} from "../firebaseconfig_Vendor";
import { toast } from "../toast";
import {
  mail,
  map,
  call,
  walk,
  checkmarkOutline,
  star,
  starHalf,
  starHalfOutline,
  starOutline,
  cartOutline,
  walkOutline,
  locationOutline,
  timeOutline,
  mailOutline,
  callOutline,
  cubeOutline,
  checkmarkCircleOutline,
  addOutline,
} from "ionicons/icons";
import { db } from "../firestoreconfig";
import { useSelector } from "react-redux";
import "../theme/vendor.css";

const average = (arr: number[]) => {
  return arr.reduce((p, c) => p + c, 0) / arr.length;
};

const ServicePage: React.FC = (props) => {
  //History gives you access to the previous route.
  let history = useHistory();
  const [busy, setBusy] = useState<boolean>(false);

  const pageProps: any = props as object;
  const serviceID = pageProps.match.params.serviceID;
  const vendorID = pageProps.match.params.vendorID;

  const [queueButtonIsDisabled, setQueueButtonIsDisabled] =
    useState<boolean>(false);

  //one-time render stuff
  const [renderIndex, setRenderIndex] = useState<number>(0);
  const user = useSelector((state: any) => state.user.data);

  if (renderIndex < 1) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var liveNotifCheck = db
      .collection("users")
      .doc(user.uid)
      .collection("notifications")
      .where("time", ">=", new Date(new Date().getTime() - 60000))
      .onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          if (window.location.pathname.split("/")[1] === "service") {
            if (change.type === "added") {
              if (change.doc.data().type === "service") {
                toast(
                  change.doc.data().vendor.name +
                    " " +
                    (change.doc.data().didAccept ? "accepted" : "rejected") +
                    " your entry to their queue for " +
                    change.doc.data().service.name +
                    ".",
                  10000,
                  "dark",
                  [
                    {
                      side: "end",
                      text: "View",
                      handler: () => {
                        history.push("/dashboard/history");
                      },
                    },
                  ]
                );
              }
            }
          }
        });
      });

    getVendorInfo();
    setRenderIndex(renderIndex + 1);
  }
  //
  const [vendorData, setVendorData] = useState<vendor>();

  async function getVendorInfo() {
    const response = (await getVendorData(vendorID)) as vendor;
    if (response) {
      if (response.serviceData[serviceID]) {
        setVendorData(response);
      } else {
        toast("This service is unavailable at the moment.", 4000, "danger");
        history.push("/dashboard");
      }
    }
  }

  const [showQueueAlert, setShowQueueAlert] = useState<boolean>(false);

  async function requestQueue() {
    setBusy(true);
    const isAlreadyInQueue = await checkIfAlreadyInQueue(
      vendorData!.id,
      serviceID
    );
    if (isAlreadyInQueue) {
      toast("You are already in the queue.", 3000, "danger");
      setBusy(false);
    } else {
      const response = await requestEntryToQueue(vendorData!, serviceID);
      if (response) {
        toast(
          "Your request has been sent and you will receive a response shortly.",
          5000,
          "success"
        );
        setBusy(false);
        setQueueButtonIsDisabled(true);
      } else {
        toast("Could not send request to enter queue.", 2000, "danger");
        setBusy(false);
      }
    }
  }

  const [galleryImages, setGalleryImages] = useState<Array<String>>([
    "https://media.istockphoto.com/photos/closeup-white-or-light-grey-colors-fabric-sample-texturelight-grey-picture-id1131975692?s=612x612",
    "https://media.istockphoto.com/photos/closeup-white-or-light-grey-colors-fabric-sample-texturelight-grey-picture-id1131975692?s=612x612",
    "https://media.istockphoto.com/photos/closeup-white-or-light-grey-colors-fabric-sample-texturelight-grey-picture-id1131975692?s=612x612",
    "https://media.istockphoto.com/photos/closeup-white-or-light-grey-colors-fabric-sample-texturelight-grey-picture-id1131975692?s=612x612",
    "https://media.istockphoto.com/photos/closeup-white-or-light-grey-colors-fabric-sample-texturelight-grey-picture-id1131975692?s=612x612",
    "https://media.istockphoto.com/photos/closeup-white-or-light-grey-colors-fabric-sample-texturelight-grey-picture-id1131975692?s=612x612",
    "https://media.istockphoto.com/photos/closeup-white-or-light-grey-colors-fabric-sample-texturelight-grey-picture-id1131975692?s=612x612",
    "https://media.istockphoto.com/photos/closeup-white-or-light-grey-colors-fabric-sample-texturelight-grey-picture-id1131975692?s=612x612",
  ]);
  const [gallerySelected, setGallerySelected] = useState<String>(
    galleryImages ? galleryImages[0] : ""
  );
  const [commentSection, setCommentSection] = useState<string>("store");

  return (
    <IonPage>
      <IonLoading isOpen={busy} message={"Please wait."} spinner="dots" />
      <IonAlert
        isOpen={showQueueAlert}
        onDidDismiss={() => setShowQueueAlert(false)}
        cssClass="alertBox"
        header={"Enter Queue?"}
        message={
          "<b>Are you sure you want to enter the queue for this service?</b><br/><br/>You will be registered only after the Vendor accepts your request."
        }
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "Yes",
            handler: () => {
              requestQueue();
            },
          },
        ]}
        backdropDismiss={false}
      />
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/dashboard" />
          </IonButtons>
          <IonTitle className="eina">
            {/*vendorData ? (
              vendorData.serviceData[serviceID].name
            ) : (
              <IonSkeletonText animated style={{ width: "60%" }} />
            )*/}{" "}
            Ecogo
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="header tags">
          <IonButton shape="round" size="small" color="light">
            Retail
          </IonButton>
          <IonButton shape="round" size="small" color="light">
            Second Hand
          </IonButton>
          <IonButton shape="round" size="small" color="light">
            Cosmetics
          </IonButton>
        </div>

        <div className="ion-padding">
          <div className="gallerySlider">
            {galleryImages.map((e) => {
              return (
                <img
                  onClick={() => setGallerySelected(e)}
                  className="gallerythumb"
                  src={"" + e}
                  alt=""
                />
              );
            })}
          </div>
          <img className="galleryShow" src={"" + gallerySelected} alt="" />

          <h3 className="eina">
            Real Strawberry and Hibiscus Rosehip bars of soap
          </h3>
          <h1 className="eina">HK$ 39.99</h1>

          <h4 className="infoH">
            <IonIcon icon={checkmarkOutline} slot="start" /> In Stock
          </h4>
          <h4 className="infoH">
            <IonIcon icon={checkmarkOutline} slot="start" /> Online Shipping
          </h4><IonText className="reviewOverview">
                  302 Reviews | <IonIcon icon={star}></IonIcon>
                  <IonIcon icon={star}></IonIcon>
                  <IonIcon icon={star}></IonIcon>
                  <IonIcon icon={starHalf}></IonIcon>
                  <IonIcon icon={starOutline}></IonIcon>
                </IonText>

                <br/><br/>

<IonItem>
<IonLabel>Variant</IonLabel>
<IonSelect placeholder="Select One" interface="action-sheet">
<IonSelectOption value="1">Variant 1</IonSelectOption>
<IonSelectOption value="2">Variant 2</IonSelectOption>
</IonSelect>
</IonItem>


<IonItem>
            <IonLabel>Quantity</IonLabel>
            <IonSelect placeholder="Select One" interface="action-sheet">
              <IonSelectOption value="1">1</IonSelectOption>
              <IonSelectOption value="2">2</IonSelectOption>
            </IonSelect>
          </IonItem>
          <br/><br/>
                <IonButton expand="full">
            <IonIcon slot="start" icon={walkOutline}/>Visit &amp; Buy</IonButton>
          <IonButton expand="full">
            <IonIcon slot="start" icon={cartOutline} /> Order Online
          </IonButton>

          <IonCard
            style={{ boxShadow: "none" }}
            className="locationCard"
            onClick={() => history.push("/vendor/" + 101)}
          >
            <div
              className="cardImage"
              style={{
                background:
                  "url(https://media.istockphoto.com/photos/closeup-white-or-light-grey-colors-fabric-sample-texturelight-grey-picture-id1131975692?s=612x612)",
              }}
            ></div>

            <div className="cardContent">
              <IonCardHeader>
                <IonCardTitle className="eina">Rebooked</IonCardTitle>

                <IonCardSubtitle>
                  Rebooked provides reused english books for everyone.
                </IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonCardSubtitle>
                  S403, Block A, PMQ, 35 Aberdeen Street, Central, Hong Kong
                </IonCardSubtitle>
                <IonCardSubtitle color="dark">
                  302 Views | <IonIcon icon={star}></IonIcon>
                  <IonIcon icon={star}></IonIcon>
                  <IonIcon icon={star}></IonIcon>
                  <IonIcon icon={starHalf}></IonIcon>
                  <IonIcon icon={starOutline}></IonIcon>
                </IonCardSubtitle>
              </IonCardContent>
            </div>
          </IonCard>

          <IonCard>
            <IonCardHeader>
              <IonCardTitle className="eina">Shipping Information</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
              <h2 className="infoH">
                <IonIcon icon={locationOutline} slot="start" /> Location
              </h2>
              <p>S403, Block A, PMQ, 35 Aberdeen Street, Central, Hong Kong</p>
              <IonGrid style={{ paddingLeft: 0 }}>
                <IonRow>
                  <IonCol style={{ paddingLeft: 0 }}>
                    <h2 className="infoH">
                      <IonIcon icon={timeOutline} slot="start" /> Duration
                    </h2>
                  </IonCol>
                  <IonCol style={{ textAlign: "right" }}>
                    <p>4 Days</p>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol style={{ paddingLeft: 0 }}>
                    <h2 className="infoH">
                      <IonIcon icon={mailOutline} slot="start" /> Inquiry
                    </h2>
                  </IonCol>
                  <IonCol style={{ textAlign: "right" }}>
                    <p>info@rebooked.com</p>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol style={{ paddingLeft: 0 }}>
                    <h2 className="infoH">
                      <IonIcon icon={cubeOutline} slot="start" /> Online
                      Shipping
                    </h2>
                  </IonCol>
                  <IonCol style={{ textAlign: "right" }}>
                    <p>Yes</p>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
          <IonCard>
            <IonCardHeader>
              <IonCardTitle className="eina">EcoGo Verified</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
              <h2 className="infoH">
                <IonIcon icon={checkmarkCircleOutline} slot="start" /> Vegan
              </h2>
              <h2 className="infoH">
                <IonIcon icon={checkmarkCircleOutline} slot="start" /> Second
                Hand
              </h2>
            </IonCardContent>
          </IonCard>

          <IonCard>
            <IonCardHeader>
              <IonCardTitle className="eina">Description</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Imperdiet dictumst consectetur metus aliquam ac, vitae viverra.
                Volutpat mauris integer sit eu ornare tellus. Duis eget id sit
                morbi cras tellus luctus. Ut est congue justo sodales lorem
                amet, eu vel arcu.
              </p>
            </IonCardContent>
          </IonCard>

          <IonSegment
            value={commentSection}
            onIonChange={(e) => setCommentSection(e.detail.value!)}
          >
            <IonSegmentButton value="item">
              <IonLabel>Item Reviews</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="all">
              <IonLabel>All Reviews</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          {commentSection === "store" ? (
            <>
              <div className="header reviews">
                <h3>Item Rating</h3>
                <IonText className="reviewOverview">
                  302 Reviews | <IonIcon icon={star}></IonIcon>
                  <IonIcon icon={star}></IonIcon>
                  <IonIcon icon={star}></IonIcon>
                  <IonIcon icon={starHalf}></IonIcon>
                  <IonIcon icon={starOutline}></IonIcon>
                </IonText>
              </div>
              <div className="addReviewBtn">
                <IonButton fill="outline">
                  <IonIcon slot="start" icon={addOutline} /> Write A Review
                </IonButton>
              </div>
              <IonItem>
                <IonAvatar slot="start">
                  <img
                    alt=""
                    src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y"
                  />
                </IonAvatar>
                <IonLabel>
                  <h3>
                    Lan Song <span className="grey-text">11 Friends</span>{" "}
                    <span className="grey-text">3 Reviews</span>
                  </h3>
                  <p className="reviewOverview">
                    <IonIcon icon={star}></IonIcon>
                    <IonIcon icon={star}></IonIcon>
                    <IonIcon icon={star}></IonIcon>
                    <IonIcon icon={starHalf}></IonIcon>
                    <IonIcon icon={starOutline}></IonIcon>
                    <span className="grey-text"> 21/7/2021</span>
                  </p>
                  <p
                    onClick={(e) =>
                      (e.currentTarget.style.whiteSpace = "break-spaces")
                    }
                    style={{ fontSize: "16px" }}
                  >
                    Quite possibly the best shop i’ve ever been to in my entire
                    life. Extremely heartwarming.{" "}
                  </p>
                </IonLabel>
              </IonItem>
            </>
          ) : null}
          {commentSection === "all" ? (
            <>
              <div className="header reviews">
                <h3>All Ratings for this Store</h3>
                <IonText className="reviewOverview">
                  302 Reviews | <IonIcon icon={star}></IonIcon>
                  <IonIcon icon={star}></IonIcon>
                  <IonIcon icon={star}></IonIcon>
                  <IonIcon icon={starHalf}></IonIcon>
                  <IonIcon icon={starOutline}></IonIcon>
                </IonText>
              </div>

              <IonItem>
                <IonAvatar slot="start">
                  <img
                    alt=""
                    src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y"
                  />
                </IonAvatar>
                <IonLabel>
                  <h3>
                    Lan Song <span className="grey-text">11 Friends</span>{" "}
                    <span className="grey-text">3 Reviews</span>
                  </h3>
                  <p className="reviewOverview">
                    <IonIcon icon={star}></IonIcon>
                    <IonIcon icon={star}></IonIcon>
                    <IonIcon icon={star}></IonIcon>
                    <IonIcon icon={starHalf}></IonIcon>
                    <IonIcon icon={starOutline}></IonIcon>
                    <span className="grey-text"> 21/7/2021</span>
                  </p>
                  <p
                    onClick={(e) =>
                      (e.currentTarget.style.whiteSpace = "break-spaces")
                    }
                    style={{ fontSize: "16px" }}
                  >
                    Quite possibly the best shop i’ve ever been to in my entire
                    life. Extremely heartwarming.{" "}
                  </p>
                  <p className="grey-text">
                    Review Subject: Brick and Mortar Location
                  </p>
                </IonLabel>
              </IonItem>
            </>
          ) : null}
          {/*vendorData ? (
          <IonContent>
            <IonItem
              detail={true}
              button={true}
              onClick={() => history.push("/vendor/" + vendorData.id)}
            >
              <IonAvatar slot="start">
                <img
                  src={require("../images/markers/" +
                    vendorData.category +
                    ".png").default}
                  alt=""
                />
              </IonAvatar>
              <IonLabel className="ion-text-wrap">
                <p>Service brought to you by</p>
                <h2 className="eina">{vendorData.name}</h2>
                <br />
                <p style={{ fontSize: "0.7em" }}>
                  Click to know more about this Vendor and check out their other
                  services.
                </p>
              </IonLabel>
            </IonItem>
            <IonButton
              expand="full"
              size="large"
              className="eina"
              color="warning"
              onClick={() => setShowQueueAlert(true)}
              disabled={queueButtonIsDisabled}
            >
              Enter Queue
              <IonIcon icon={walk} slot="start"></IonIcon>
            </IonButton>
            <IonItem>
              <IonLabel className="ion-text-wrap">
                <h4 className="eina">Description</h4>
                <p>{vendorData.serviceData[serviceID].description}</p>
              </IonLabel>
            </IonItem>
            {vendorData.serviceData[serviceID].rating ? (
              <IonItem>
                <IonLabel className="ion-text-wrap">
                  <h4 className="eina">Customer Rating</h4>
                  {Array(
                    Math.floor(
                      average(vendorData.serviceData[serviceID].rating)
                    )
                  ).fill(<IonIcon icon={star} color="warning"></IonIcon>)}
                  {average(vendorData.serviceData[serviceID].rating) -
                    Math.floor(
                      average(vendorData.serviceData[serviceID].rating)
                    ) >=
                  0.4 ? (
                    <IonIcon icon={starHalf} color="warning"></IonIcon>
                  ) : null}
                  <p>
                    {vendorData.serviceData[serviceID].rating.length} users
                    rated this service{" "}
                    <b>
                      {
                        +average(
                          vendorData.serviceData[serviceID].rating
                        ).toFixed(1)
                      }{" "}
                      stars
                    </b>{" "}
                    out of 5.
                  </p>
                </IonLabel>
              </IonItem>
            ) : null}
            {!vendorData.serviceData[serviceID].isGeneral ? (
              <IonItem>
                <IonAvatar slot="start">
                  <img
                    alt=""
                    src={require("../images/misc/rupee.png").default}
                    style={{ borderRadius: 0, width: "auto", height: "100%" }}
                  />
                </IonAvatar>
                <IonLabel className="ion-text-wrap">
                  <h4 className="eina">Price</h4>
                  <h2>
                    {!vendorData.serviceData[serviceID].isGeneral &&
                    vendorData.serviceData[serviceID].isDiscounted ? (
                      <span
                        style={{
                          color: "black",
                          textDecoration: "line-through",
                        }}
                      >
                        <span style={{ color: "red" }}>
                          {vendorData.serviceData[serviceID].originalPrice +
                            " "}
                        </span>
                      </span>
                    ) : null}
                    {vendorData.serviceData[serviceID].price}
                  </h2>
                </IonLabel>
              </IonItem>
            ) : null}
            <IonItem>
              <IonAvatar
                slot="start"
                className="eina"
                style={{ fontSize: "3em", lineHeight: "45px" }}
              >
                {vendorData.serviceData[serviceID].queue}
              </IonAvatar>
              <IonLabel className="ion-text-wrap">
                <h4 className="eina">Current Queue</h4>
                <p>
                  There are {vendorData.serviceData[serviceID].queue} people
                  lined up in the queue.
                </p>
                {!vendorData.serviceData[serviceID].isGeneral ? (
                  <p>
                    Approximate time for queue to clear is{" "}
                    {vendorData.serviceData[serviceID].queue *
                      vendorData.serviceData[serviceID].duration}{" "}
                    minutes.
                  </p>
                ) : null}
              </IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonGrid>
                <IonRow>
                  <IonCol size="4">
                    <IonButton
                      expand="full"
                      size="default"
                      color="success"
                      onClick={() =>
                        window.open("tel:" + vendorData.phone, "_system")
                      }
                    >
                      <IonIcon icon={call}></IonIcon>
                    </IonButton>
                  </IonCol>
                  <IonCol size="4">
                    <IonButton
                      expand="full"
                      size="default"
                      color="danger"
                      onClick={() =>
                        window.open("mailto:" + vendorData.email, "_system")
                      }
                    >
                      <IonIcon icon={mail}></IonIcon>
                    </IonButton>
                  </IonCol>
                  <IonCol size="4">
                    <IonButton
                      expand="full"
                      size="default"
                      color="primary"
                      onClick={() =>
                        window.open(
                          "geo:" +
                            vendorData.location.coords.lat +
                            "," +
                            vendorData.location.coords.lng,
                          "_system"
                        )
                      }
                    >
                      <IonIcon icon={map}></IonIcon>
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
          </IonContent>
        ) : (
          <div className="ion-padding custom-skeleton">
            <IonSkeletonText animated style={{ width: "60%" }} />
            <IonSkeletonText animated />
            <IonSkeletonText animated style={{ width: "88%" }} />
            <IonSkeletonText animated style={{ width: "70%" }} />
            <IonSkeletonText animated style={{ width: "60%" }} />
          </div>
        )*/}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ServicePage;
