import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonCard,
  IonButton,
  IonIcon,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonGrid,
  IonCol,
  IonRow,
  IonSkeletonText,
  IonItem,
  IonAvatar,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonModal,
  IonList,
  IonInput,
} from "@ionic/react";
import React, { useRef, useState } from "react";
import "./Home.css";
import { review, vendor } from "../myTypes";
import { getVendorData } from "../firebaseconfig_Vendor";
import {
  mailOutline,
  callOutline,
  mapOutline,
  locationOutline,
  cubeOutline,
  globeOutline,
  addOutline,
  personCircleOutline,
  arrowBack,
  starOutline,
  star,
  cloudUploadOutline,
  closeOutline,
  starHalfOutline,
  pencilOutline,
} from "ionicons/icons";
import { useHistory } from "react-router";
import { db, deleteReview, getReviews, saveReview } from "../firestoreconfig";
import { useSelector } from "react-redux";
import { toast } from "../toast";
import "../theme/vendor.css";
import { getBase64FromUrl, imgfy } from "../config";

const VendorPage: React.FC = (props) => {
  let history = useHistory();

  //one-time render stuff
  const [renderIndex, setRenderIndex] = useState<number>(0);
  const user = useSelector((state: any) => state.user.data);

  if (renderIndex < 1) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // var liveNotifCheck = db
    //   .collection("users")
    //   .doc(user.uid)
    //   .collection("notifications")
    //   .where("time", ">=", new Date(new Date().getTime() - 60000))
    //   .onSnapshot(function (snapshot) {
    //     snapshot.docChanges().forEach(function (change) {
    //       if (window.location.pathname.split("/")[1] === "vendor") {
    //         if (change.type === "added") {
    //           if (change.doc.data().type === "service") {
    //             toast(
    //               change.doc.data().vendor.name +
    //                 " " +
    //                 (change.doc.data().didAccept ? "accepted" : "rejected") +
    //                 " your entry to their queue for " +
    //                 change.doc.data().service.name +
    //                 ".",
    //               10000,
    //               "dark",
    //               [
    //                 {
    //                   side: "end",
    //                   text: "View",
    //                   handler: () => {
    //                     history.push("/dashboard/history");
    //                   },
    //                 },
    //               ]
    //             );
    //           }
    //         }
    //       }
    //     });
    //   });
    getVendorInfo();
    loadReviews();
    setRenderIndex(renderIndex + 1);
  }
  //
  const [vendorData, setVendorData] = useState<vendor>();
  const [reviewsData, setReviewsData] = useState<review[]>([]);

  async function getVendorInfo() {
    const pageProps: any = props as object;
    const vendorID = pageProps.match.params.vendorID;
    const response = (await getVendorData(vendorID)) as vendor;
    if (response) {
      setVendorData(response);
      if (
        (response.location.coords.lat === 0 &&
          response.location.coords.lng === 0) ||
        response.location.name.trim().toLowerCase() === "online"
      ) {
        setIsOnline(true);
      }
    }
  }

  async function loadReviews() {
    const pageProps: any = props as object;
    const vendorID = pageProps.match.params.vendorID;
    var res = await getReviews(vendorID);

    if (res) {
      setUserPrevReview(undefined);
      var storeCount = 0;
      var allCount = 0;
      var storeSum = 0;
      var allSum = 0;
      (res as review[]).map((e) => {
        if (e.user.id === user.uid) {
          setUserPrevReview(e);
        }
        allCount++;
        allSum += e.rating;
        if (e.type === "store") {
          storeSum += e.rating;
          storeCount++;
        }
        return null;
      });
      setStats({
        allReviews: allCount,
        storeReviews: storeCount,
        allAvg: allSum / allCount,
        storeAvg: storeSum / storeCount,
      });
      setReviewsData(res as review[]);
    } else {
      toast("Unable to load reviews.", 1500, "danger");
    }
  }

  async function postReview() {
    if (starRating) {
      let res = await saveReview(
        vendorData!,
        starRating,
        reviewText,
        reviewImagesURL,
        userPrevReview?.id
      );
      if (res) {
        toast("Review sucsessfully posted.", 2000, "success");
        setStarRating(undefined);
        setReviewText("");
        setReviewImagesURL([]);
        setShowWriteReviewModal(false);
        loadReviews();
      } else {
        toast("Could not post review.", 1500, "danger");
      }
    } else {
      toast("A star rating is mandatory.", 1500, "danger");
    }
  }

  async function deleteMyReview() {
    let res = await deleteReview(userPrevReview!.id);
    if (res) {
      toast("Review sucsessfully deleted.", 2000, "success");
      loadReviews();
    } else {
      toast("Could not delete review.", 2000, "success");
    }
  }

  function initEditReview() {
    setStarRating(userPrevReview!.rating);
    setReviewText(userPrevReview!.review);
    let i = 0;
    let l: string[] = [];
    for (var x = 0; x < userPrevReview!.images.length; x++) {
      // eslint-disable-next-line no-loop-func
      getBase64FromUrl(userPrevReview!.images[x], "ecogollc").then((r) => {
        l.push(r + "");
        i++;
        if (i === userPrevReview!.images.length) {
          setReviewImagesURL((reviewImagesURL) => [...l]);
        }
      });
    }
    setShowWriteReviewModal(true);
  }

  const [gallerySelected, setGallerySelected] = useState<number>(0);
  const [commentSection, setCommentSection] = useState<string>("store");
  const [isOnline, setIsOnline] = useState<boolean>(false);

  const [showWriteReviewModal, setShowWriteReviewModal] =
    useState<boolean>(false);
  const [starRating, setStarRating] = useState<number>();
  const [reviewText, setReviewText] = useState<string>("");
  const uploadReviewImagesRef = useRef<HTMLInputElement>(null);
  const [reviewImagesURL, setReviewImagesURL] = useState<Array<string>>([]);

  const [stats, setStats] = useState<{
    allReviews: number;
    storeReviews: number;
    allAvg: number;
    storeAvg: number;
  }>({ allReviews: 0, storeReviews: 0, allAvg: 0, storeAvg: 0 });

  const [showImgPreview, setShowImgPreview] = useState<boolean>(false);
  const [previewImgSrc, setPreviewImgSrc] = useState<string>("");

  const [userPrevReview, setUserPrevReview] = useState<review>();

  function previewImage(src: string) {
    setShowImgPreview(true);
    setPreviewImgSrc(src);
  }

  return (
    <>
      <div id="imgPrev" hidden={!showImgPreview}>
        <IonButton
          slot="start"
          onClick={() => setShowImgPreview(false)}
          fill="clear"
        >
          <IonIcon icon={closeOutline}></IonIcon>
        </IonButton>
        <img src={previewImgSrc} alt="" />
      </div>
      <IonModal isOpen={showWriteReviewModal} backdropDismiss={false}>
        <IonToolbar color="clear">
          <IonButton
            slot="start"
            onClick={() => setShowWriteReviewModal(false)}
            fill="clear"
          >
            <IonIcon icon={arrowBack}></IonIcon>
          </IonButton>
          <IonTitle className="eina">Write a review</IonTitle>
        </IonToolbar>
        <IonContent className="ion-padding">
          <p>
            Write a review for {vendorData?.name} and contribute to the
            community.
          </p>
          <IonList>
            <IonItem lines="none">Give a star rating.</IonItem>
            <IonItem>
              <div id="starRatingBox">
                {[1, 2, 3, 4, 5].map((e) => (
                  <IonIcon
                    key={e}
                    onClick={() => {
                      setStarRating(e);
                    }}
                    icon={
                      starRating
                        ? starRating >= e
                          ? star
                          : starOutline
                        : starOutline
                    }
                  ></IonIcon>
                ))}
              </div>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">
                Feedback <span className="grey-text">(Optional)</span>
              </IonLabel>
              <IonInput
                value={reviewText}
                type="text"
                onIonChange={(e: any) => setReviewText(e.target.value)}
                placeholder="What did you like or dislike about this store?"
              />
            </IonItem>
            <IonItem lines="none">
              <h4 className="eina">
                Include Images <span className="grey-text">(Optional)</span>
                <label className="uploadBtn">
                  <IonButton
                    size="small"
                    shape="round"
                    onClick={() => {
                      if (uploadReviewImagesRef !== null) {
                        uploadReviewImagesRef!.current!.click();
                      }
                    }}
                  >
                    <IonIcon slot="end" icon={cloudUploadOutline}></IonIcon>
                    Add Image
                  </IonButton>
                </label>
              </h4>
              <input
                type="file"
                accept=".jpg, .jpeg, .png, .gif"
                style={{ display: "none" }}
                ref={uploadReviewImagesRef}
                onChange={(e) => {
                  var fr = new FileReader();
                  fr.onload = function (e) {
                    let temp = reviewImagesURL;
                    temp.push(this.result + "");
                    setReviewImagesURL((reviewImagesURL) => [...temp]);
                  };
                  if (e!.target!.files![0])
                    fr.readAsDataURL(e!.target!.files![0]);
                }}
              />
            </IonItem>
            {reviewImagesURL.map((e, k) => (
              <IonItem key={k}>
                <img className="boxedImage" alt="" src={e} />
                <IonButton
                  size="small"
                  color="danger"
                  shape="round"
                  className="imageRemoveBtn"
                  onClick={() => {
                    let temp = reviewImagesURL;
                    temp.splice(k, 1);
                    setReviewImagesURL((reviewImagesURL) => [...temp]);
                  }}
                >
                  <IonIcon icon={closeOutline} slot="start"></IonIcon>Remove
                </IonButton>
              </IonItem>
            ))}
          </IonList>
          <IonButton onClick={() => postReview()} color="primary">
            Post Review
          </IonButton>
        </IonContent>
      </IonModal>
      <IonPage>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/dashboard" />
            </IonButtons>
            <IonTitle className="eina">EcoGo</IonTitle>
          </IonToolbar>
        </IonHeader>
        {vendorData ? (
          <IonContent>
            <div
              className="header cover"
              style={{
                background: "url(" + imgfy(vendorData.images.banner!) + ")",
              }}
            >
              {/*<IonButton shape="round" size="small">
          <IonIcon slot="start" icon={heartOutline} />
          2.2K Likes
      </IonButton>*/}
            </div>

            <div className="header tags">
              {vendorData.category.map((e, k) => {
                return (
                  <IonButton key={k} shape="round" size="small" color="light">
                    {e}
                  </IonButton>
                );
              })}
            </div>

            <div className="ion-padding">
              <IonCard style={{ boxShadow: "none" }} className="locationCard">
                <div
                  className="cardImage logo"
                  style={{
                    background: "url(" + imgfy(vendorData.images.logo!) + ")",
                  }}
                ></div>

                <div className="cardContent">
                  <IonCardHeader>
                    <IonCardTitle className="eina">
                      {vendorData.name}
                    </IonCardTitle>

                    <IonCardSubtitle>{vendorData.summary}</IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonCardSubtitle>
                      {vendorData.location.name}
                    </IonCardSubtitle>
                    {/*<IonCardSubtitle color="dark">
                302 Views | <IonIcon icon={star}></IonIcon>
                <IonIcon icon={star}></IonIcon>
                <IonIcon icon={star}></IonIcon>
                <IonIcon icon={starHalf}></IonIcon>
                <IonIcon icon={starOutline}></IonIcon>
          </IonCardSubtitle>*/}
                  </IonCardContent>
                </div>
              </IonCard>
              <div className="gallerySlider">
                {vendorData.images.other.map((e, k) => {
                  return (
                    <img
                      onClick={() => setGallerySelected(k)}
                      className="gallerythumb"
                      src={imgfy(e)}
                      alt=""
                      key={k}
                    />
                  );
                })}
              </div>
              <img
                className="galleryShow"
                src={imgfy(vendorData.images.other[gallerySelected]!)}
                alt=""
              />
              <IonButton
                expand="full"
                hidden={isOnline}
                onClick={() =>
                  window.open(
                    "https://www.google.com/maps/search/?api=1&query=" +
                      vendorData.location.coords.lat +
                      "," +
                      vendorData.location.coords.lng +
                      (vendorData.location.id && vendorData.location.id !== ""
                        ? "&query_place_id=" + vendorData.location.id
                        : "")
                  )
                }
              >
                <IonIcon slot="start" icon={mapOutline} /> Get Directions
              </IonButton>
              {/*
          <IonButton expand="full">
            <IonIcon slot="start" icon={bookmarkOutline} /> Bookmark
          </IonButton>*/}
              <div hidden={isOnline}>
                {/* <Map
                // eslint-disable-next-line react/style-prop-object
                style={"mapbox://styles/mapbox/streets-v9"}
                containerStyle={{
                  height: "200px",
                  width: "100%",
                }}
                center={[
                  Number(vendorData.location.coords.lng),
                  Number(vendorData.location.coords.lat),
                ]}
                zoom={[15]}
              >
                <Marker
                  coordinates={[
                    +vendorData.location.coords.lng!,
                    +vendorData.location.coords.lat!,
                  ]}
                  anchor="bottom"
                >
                  <img
                    alt=""
                    src={require("../images/markers/primary.png").default}
                  />
                </Marker>
              </Map> */}
                <iframe
                  style={{ width: "100%", border: "0" }}
                  src={
                    "https://www.google.com/maps/embed/v1/place?key=AIzaSyAkwRNpKQ8-EfvObWvWStBgL4B6Mj9qQ6E&q=" +
                    (vendorData.location.id && vendorData.location.id !== ""
                      ? "place_id:" + vendorData.location.id
                      : vendorData.location.coords.lat +
                        "," +
                        vendorData.location.coords.lng)
                  }
                  title="Map"
                ></iframe>
              </div>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle className="eina">
                    Store Information
                  </IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                  <h2 className="infoH">
                    <IonIcon icon={locationOutline} slot="start" /> Location
                  </h2>
                  <p>{vendorData.location.name}</p>
                  <IonGrid style={{ paddingLeft: 0 }}>
                    {/*<IonRow>
                <IonCol style={{ paddingLeft: 0 }}>
                  <h2 className="infoH">
                    <IonIcon icon={timeOutline} slot="start" /> Opening Hours
                  </h2>
                </IonCol>
                <IonCol style={{ textAlign: "right" }}>
                  <p>9:00 - 21:00</p>
                </IonCol>
              </IonRow>
              */}
                    <IonRow>
                      <IonCol style={{ paddingLeft: 0 }}>
                        <h2 className="infoH">
                          <IonIcon icon={mailOutline} slot="start" /> Email
                        </h2>
                      </IonCol>
                      <IonCol style={{ textAlign: "right" }}>
                        <p>{vendorData.email}</p>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol style={{ paddingLeft: 0 }}>
                        <h2 className="infoH">
                          <IonIcon icon={callOutline} slot="start" /> Phone
                          Number
                        </h2>
                      </IonCol>
                      <IonCol style={{ textAlign: "right" }}>
                        <p>{vendorData.phone}</p>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol style={{ paddingLeft: 0 }}>
                        <h2 className="infoH">
                          <IonIcon icon={cubeOutline} slot="start" /> Store Type
                        </h2>
                      </IonCol>
                      <IonCol style={{ textAlign: "right" }}>
                        <p>
                          {vendorData.type === "physical"
                            ? "Physical Retail Only"
                            : vendorData.type === "physical_shipping"
                            ? "Physical Retail and Online Shipping"
                            : "Online shipping Only"}
                        </p>
                      </IonCol>
                    </IonRow>
                    {vendorData.weblink ? (
                      <IonRow>
                        <IonCol style={{ paddingLeft: 0 }}>
                          <h2 className="infoH">
                            <IonIcon icon={globeOutline} slot="start" /> Web
                          </h2>
                        </IonCol>
                        <IonCol style={{ textAlign: "right" }}>
                          <p>
                            {vendorData.weblink.trim().slice(0, 8) ===
                              "https://" ||
                            vendorData.weblink.trim().slice(0, 7) ===
                              "http://" ? (
                              <a href={vendorData.weblink.trim()}>
                                {vendorData.weblink.trim().split("://")[1]}
                              </a>
                            ) : (
                              <a href={"http://" + vendorData.weblink.trim()}>
                                {vendorData.weblink.trim()}
                              </a>
                            )}
                          </p>
                        </IonCol>
                      </IonRow>
                    ) : (
                      ""
                    )}
                  </IonGrid>
                </IonCardContent>
              </IonCard>
              {/*}
        <IonCard>
          <IonCardHeader>
            <IonCardTitle className="eina">EcoGo Verified</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            <h2 className="infoH">
              <IonIcon icon={checkmarkCircleOutline} slot="start" /> Vegan
            </h2>
            <h2 className="infoH">
              <IonIcon icon={checkmarkCircleOutline} slot="start" /> Second
              Hand
            </h2>
          </IonCardContent>
        </IonCard>
        */}
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle className="eina">Description</IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                  <p>{vendorData.description}</p>
                </IonCardContent>
              </IonCard>
              <p>
                Do you own this business?{" "}
                <a href="https://forms.gle/UFVT4opREQjC3kBVA">
                  Click here to claim it.
                </a>
              </p>

              {/*}
        <IonLabel className="eina ion-text-wrap">
          <h1
            className="eina"
            style={{ textAlign: "center", marginTop: "20px" }}
          >
            Products
          </h1>
        </IonLabel>

        <IonCard
          className="productCard"
          onClick={() => history.push("/service/" + 101 + "/" + 0)}
        >
          <div
            className="cardImage"
            style={{
              background:
                "url(https://media.istockphoto.com/photos/closeup-white-or-light-grey-colors-fabric-sample-texturelight-grey-picture-id1131975692?s=612x612)",
            }}
          ></div>
          <div className="cardContent">
            <IonCardHeader>
              <IonCardTitle className="eina">
                Real Strawberry and Hibiscus
              </IonCardTitle>

              <IonCardSubtitle>Rebooked</IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <IonCardSubtitle color="dark">
                302 Views | <IonIcon icon={star}></IonIcon>
                <IonIcon icon={star}></IonIcon>
                <IonIcon icon={star}></IonIcon>
                <IonIcon icon={starHalf}></IonIcon>
                <IonIcon icon={starOutline}></IonIcon>
              </IonCardSubtitle>
              <IonCardTitle className="eina">HK$ 7.99</IonCardTitle>
            </IonCardContent>
          </div>
        </IonCard>
          */}

              <IonSegment
                value={commentSection}
                onIonChange={(e) => setCommentSection(e.detail.value!)}
              >
                <IonSegmentButton value="store">
                  <IonLabel>Store Reviews</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="all">
                  <IonLabel>All Reviews</IonLabel>
                </IonSegmentButton>
              </IonSegment>

              {commentSection === "store" ? (
                <>
                  <div className="header reviews">
                    <h3>Store Rating</h3>
                    <IonText className="reviewOverview">
                      {stats.storeReviews} Reviews
                      <span hidden={stats.storeReviews <= 0}>
                        {" | "}
                        {[1, 2, 3, 4, 5].map((x) => (
                          <IonIcon
                            key={x}
                            icon={
                              stats.storeAvg >= x
                                ? star
                                : Math.ceil(stats.storeAvg) === x
                                ? x - stats.storeAvg >= 0.5
                                  ? starHalfOutline
                                  : star
                                : starOutline
                            }
                          ></IonIcon>
                        ))}
                        {" Rated " +
                          Math.round((100 * stats.storeAvg) / 5) +
                          "%"}
                      </span>
                    </IonText>
                  </div>
                  <div
                    className="addReviewBtn"
                    hidden={
                      user.email === "guest@ecogohk.org" ||
                      userPrevReview !== undefined
                    }
                  >
                    <IonButton
                      fill="outline"
                      onClick={() => setShowWriteReviewModal(true)}
                    >
                      <IonIcon slot="start" icon={addOutline} /> Write A Review
                    </IonButton>
                  </div>
                  {userPrevReview && (
                    <IonItem>
                      <IonLabel>
                        <h3>Your Review </h3>

                        <p className="reviewOverview">
                          {[1, 2, 3, 4, 5].map((x) => (
                            <IonIcon
                              key={x}
                              icon={
                                userPrevReview.rating >= x ? star : starOutline
                              }
                            ></IonIcon>
                          ))}{" "}
                          <span className="grey-text">
                            {userPrevReview.time
                              .toDate()
                              .toLocaleDateString("en-UK", {
                                weekday: undefined,
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              })}
                          </span>
                        </p>
                        <p
                          onClick={(e) =>
                            (e.currentTarget.style.whiteSpace = "break-spaces")
                          }
                          style={{ fontSize: "16px" }}
                        >
                          {userPrevReview.review}
                        </p>
                        <div>
                          {userPrevReview.images.map((y, c) => (
                            <img
                              key={c}
                              alt=""
                              src={imgfy(y, "ecogollc")}
                              className="boxedImageSmall"
                              onClick={() => previewImage(imgfy(y, "ecogollc"))}
                            />
                          ))}
                        </div>
                        <p>
                          <IonButton
                            size="small"
                            color="primary"
                            shape="round"
                            className="myCommentBtn"
                            onClick={() => initEditReview()}
                          >
                            <IonIcon
                              icon={pencilOutline}
                              slot="start"
                            ></IonIcon>
                            Edit
                          </IonButton>{" "}
                          <IonButton
                            size="small"
                            color="danger"
                            shape="round"
                            className="myCommentBtn"
                            onClick={() => {
                              deleteMyReview();
                            }}
                          >
                            <IonIcon icon={closeOutline} slot="start"></IonIcon>
                            Delete
                          </IonButton>
                        </p>
                      </IonLabel>
                    </IonItem>
                  )}
                  {reviewsData.map((e, k) => {
                    if (e.type === "store")
                      return (
                        <IonItem key={k}>
                          <IonAvatar slot="start">
                            {/* <img
                    alt=""
                    src=""
                  /> */}
                            <IonIcon
                              icon={personCircleOutline}
                              style={{ width: "40px", height: "40px" }}
                            ></IonIcon>
                          </IonAvatar>
                          <IonLabel>
                            <h3>
                              {e.user.name}
                              {/* <span className="grey-text">11 Friends</span>{" "}
                          <span className="grey-text">3 Reviews</span> */}
                            </h3>
                            <p className="reviewOverview">
                              {[1, 2, 3, 4, 5].map((x) => (
                                <IonIcon
                                  key={x}
                                  icon={e.rating >= x ? star : starOutline}
                                ></IonIcon>
                              ))}{" "}
                              <span className="grey-text">
                                {e.time.toDate().toLocaleDateString("en-UK", {
                                  weekday: undefined,
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                })}
                              </span>
                            </p>
                            <p
                              onClick={(e) =>
                                (e.currentTarget.style.whiteSpace =
                                  "break-spaces")
                              }
                              style={{ fontSize: "16px" }}
                            >
                              {e.review}
                            </p>
                            <div>
                              {e.images.map((y, c) => (
                                <img
                                  key={c}
                                  alt=""
                                  src={imgfy(y, "ecogollc")}
                                  className="boxedImageSmall"
                                  onClick={() =>
                                    previewImage(imgfy(y, "ecogollc"))
                                  }
                                />
                              ))}
                            </div>
                          </IonLabel>
                        </IonItem>
                      );
                    else return null;
                  })}
                </>
              ) : null}
              {commentSection === "all" ? (
                <>
                  <div className="header reviews">
                    <h3>All Ratings for this Store</h3>
                    <IonText className="reviewOverview">
                      {stats.allReviews} Reviews
                      <span hidden={stats.storeReviews <= 0}>
                        {" | "}
                        {[1, 2, 3, 4, 5].map((x) => (
                          <IonIcon
                            key={x}
                            icon={
                              stats.allAvg >= x
                                ? star
                                : Math.ceil(stats.allAvg) === x
                                ? x - stats.allAvg >= 0.5
                                  ? starHalfOutline
                                  : star
                                : starOutline
                            }
                          ></IonIcon>
                        ))}
                        {" Rated " + Math.round((100 * stats.allAvg) / 5) + "%"}
                      </span>
                    </IonText>
                  </div>

                  {reviewsData.map((e, k) => (
                    <IonItem key={k}>
                      <IonAvatar slot="start">
                        {/* <img
                    alt=""
                    src=""
                  /> */}
                        <IonIcon
                          icon={personCircleOutline}
                          style={{ width: "40px", height: "40px" }}
                        ></IonIcon>
                      </IonAvatar>
                      <IonLabel>
                        <h3>
                          {e.user.name}
                          {/* <span className="grey-text">11 Friends</span>{" "}
                          <span className="grey-text">3 Reviews</span> */}
                        </h3>
                        <p className="reviewOverview">
                          {[1, 2, 3, 4, 5].map((x) => (
                            <IonIcon
                              key={x}
                              icon={e.rating >= x ? star : starOutline}
                            ></IonIcon>
                          ))}{" "}
                          <span className="grey-text">
                            {e.time.toDate().toLocaleDateString("en-UK", {
                              weekday: undefined,
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })}
                          </span>
                        </p>
                        <p className="grey-text">
                          Review on{" "}
                          {
                            e.type === "store" ? "Store" : "Product"
                            //"Product: " + e.product.name
                          }
                        </p>
                        <p
                          onClick={(e) =>
                            (e.currentTarget.style.whiteSpace = "break-spaces")
                          }
                          style={{ fontSize: "16px" }}
                        >
                          {e.review}
                        </p>
                        <div>
                          {e.images.map((y, c) => (
                            <img
                              key={c}
                              alt=""
                              src={imgfy(y, "ecogollc")}
                              className="boxedImageSmall"
                              onClick={() => previewImage(imgfy(y, "ecogollc"))}
                            />
                          ))}
                        </div>
                      </IonLabel>
                    </IonItem>
                  ))}
                </>
              ) : null}

              {/*vendorData ? (
        <IonContent>
          <IonItem>
            <IonAvatar slot="start">
              <img
                src={
                  require("../images/markers/" + vendorData.category + ".png")
                    .default
                }
                alt=""
              />
            </IonAvatar>
            <IonLabel className="ion-text-wrap">
              <h2 className="eina">About</h2>
              <p>{vendorData.description}</p>
            </IonLabel>
          </IonItem>
          <IonItem lines="none">
            <IonGrid>
              <IonRow>
                <IonCol size="4">
                  <IonButton
                    expand="full"
                    size="default"
                    color="success"
                    onClick={() =>
                      window.open("tel:" + vendorData.phone, "_system")
                    }
                  >
                    <IonIcon icon={call}></IonIcon>
                  </IonButton>
                </IonCol>
                <IonCol size="4">
                  <IonButton
                    expand="full"
                    size="default"
                    color="danger"
                    onClick={() =>
                      window.open("mailto:" + vendorData.email, "_system")
                    }
                  >
                    <IonIcon icon={mail}></IonIcon>
                  </IonButton>
                </IonCol>
                <IonCol size="4">
                  <IonButton
                    expand="full"
                    size="default"
                    color="primary"
                    onClick={() =>
                      window.open(
                        "geo:" +
                          vendorData.location.coords.lat +
                          "," +
                          vendorData.location.coords.lng,
                        "_system"
                      )
                    }
                  >
                    <IonIcon icon={map}></IonIcon>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
          <Map
            // eslint-disable-next-line react/style-prop-object
            style={"mapbox://styles/mapbox/streets-v9"}
            containerStyle={{
              height: "150px",
              width: "100%",
            }}
            center={[
              Number(vendorData.location.coords.lng),
              Number(vendorData.location.coords.lat),
            ]}
            zoom={[15]}
          >
            <Marker
              coordinates={[
                +vendorData.location.coords.lng!,
                +vendorData.location.coords.lat!,
              ]}
              anchor="bottom"
            >
              <img
                alt=""
                src={
                  require("../images/markers/" + vendorData.category + ".png")
                    .default
                }
              />
            </Marker>
          </Map>
          <br />
          {Object.keys(vendorData.serviceData).length > 0 ? (
            <h2 className="eina">Services</h2>
          ) : null}
          <div>
            {Object.keys(vendorData.serviceData)
              .reverse()
              .map((key: any) => (
                <IonCard
                  key={key}
                  className="serviceCard"
                  onClick={() =>
                    history.push("/service/" + vendorData.id + "/" + key)
                  }
                >
                  <IonCardHeader>
                    <IonItem lines="none" style={{ padding: "0" }}>
                      <IonCardTitle>
                        {vendorData.serviceData[key].name}
                      </IonCardTitle>
                    </IonItem>

                    <IonCardSubtitle>
                      <IonIcon icon={pricetag}></IonIcon>{" "}
                      {!vendorData.serviceData[key].isGeneral &&
                      vendorData.serviceData[key].isDiscounted ? (
                        <span
                          style={{
                            color: "black",
                            textDecoration: "line-through",
                          }}
                        >
                          <span style={{ color: "red" }}>
                            {"Rs. " +
                              vendorData.serviceData[key].originalPrice +
                              " "}
                          </span>
                        </span>
                      ) : null}
                      {vendorData.serviceData[key].isGeneral
                        ? "Price may vary"
                        : "Rs. " + vendorData.serviceData[key].price}
                    </IonCardSubtitle>
                    <IonCardSubtitle>
                      <IonIcon icon={hourglass}></IonIcon>{" "}
                      {vendorData.serviceData[key].isGeneral
                        ? "Duration may vary"
                        : vendorData.serviceData[key].duration + " minutes"}
                    </IonCardSubtitle>

                    {vendorData.serviceData[key].rating ? (
                      <IonCardSubtitle>
                        {Array(
                          Math.floor(
                            average(vendorData.serviceData[key].rating)
                          )
                        ).fill(
                          <IonIcon icon={star} color="warning"></IonIcon>
                        )}
                        {average(vendorData.serviceData[key].rating) -
                          Math.floor(
                            average(vendorData.serviceData[key].rating)
                          ) >=
                        0.4 ? (
                          <IonIcon icon={starHalf} color="warning"></IonIcon>
                        ) : null}
                      </IonCardSubtitle>
                    ) : null}
                  </IonCardHeader>
                  <IonCardContent>
                    <p>
                      <b>{vendorData.serviceData[key].description}</b>
                    </p>
                    <p style={{ color: "#dd0000" }}>
                      Current Queue:{" "}
                      <b>{vendorData.serviceData[key].queue}</b>
                    </p>
                    <p style={{ fontSize: "10px" }}>
                      Click to view details and enter the queue.
                    </p>
                  </IonCardContent>
                </IonCard>
              ))}
          </div>
        </IonContent>
      ) : (
        <div className="ion-padding custom-skeleton">
          <IonSkeletonText animated style={{ width: "60%" }} />
          <IonSkeletonText animated />
          <IonSkeletonText animated style={{ width: "88%" }} />
          <IonSkeletonText animated style={{ width: "70%" }} />
          <IonSkeletonText animated style={{ width: "60%" }} />
        </div>
      )*/}
            </div>
          </IonContent>
        ) : (
          <IonContent>
            <div className="ion-padding custom-skeleton">
              <IonSkeletonText animated style={{ width: "60%" }} />
              <IonSkeletonText animated />
              <IonSkeletonText animated style={{ width: "88%" }} />
              <IonSkeletonText animated style={{ width: "70%" }} />
              <IonSkeletonText animated style={{ width: "60%" }} />
            </div>
          </IonContent>
        )}
      </IonPage>
    </>
  );
};

export default VendorPage;
